import { Flex } from '../../../../../styled-components';
import { useTranslation } from 'react-i18next';
import { PriceInformationFormValues } from '../PriceInformationStep';
import styled from 'styled-components';
import { Title } from '../../../../../contexts';
import { Form } from 'react-bootstrap';

/**
 * The type for the "values" Component prop.
 * This higher order type is made in order not to tie the Component down to the
 * Formik implementation of the "values" object.
 */
type NewsletterSubscriptionFormValues = {
  [key in keyof Pick<PriceInformationFormValues, 'newsletterTitle' | 'newsletterName' | 'newsletterSurname'>]:
    | Title
    | string
    | undefined;
};

/**
 * The type for the "errors" Component prop.
 * This higher order type is made in order not to tie the Component down to the
 * Formik implementation of the "errors" object.
 */
type NewsletterSubscriptionFormErrors = {
  [key in keyof Pick<PriceInformationFormValues, 'newsletterTitle' | 'newsletterName' | 'newsletterSurname'>]:
    | string
    | undefined;
};

/**
 * Container for the fields.
 * Useful for the responsiveness of the Component.
 */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: 576px) {
    flex-direction: row;
  }
`;

/**
 * The label + input + tag.
 */
const Field = styled(Flex)`
  margin-right: 0;

  @media (min-width: 576px) {
    margin-right: 1.5rem;
  }
`;

const ErrorHandledRadio = styled(Form.Check)<{ error: string }>`
  input.form-check-input {
    ${({ error }) => (error === 'true' ? 'border-color: #de4756;' : '')}
  }

  label.form-check-label {
    ${({ error }) => (error === 'true' ? 'color: #de4756' : '')}
  }
`;

/**
 * The DesiredPrice props.
 */
interface NewsletterSubscriptionProps {
  /**
   * The callback to pass to the inputs as provided by Formik.
   */
  handleChange: {
    /** Classic React change handler, keyed by input name */
    // eslint-disable-next-line
    (e: React.ChangeEvent<any>): void;
    /** Preact-like linkState. Will return a handleChange function.  */
    // eslint-disable-next-line
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void // eslint-disable-next-line
      : (e: string | React.ChangeEvent<any>) => void;
  };

  /**
   * The values for the inputs.
   */
  values: NewsletterSubscriptionFormValues;

  /**
   * The possible errors for the form values.
   */
  errors: NewsletterSubscriptionFormErrors;
}

export default function NewsletterSubscription({ values, errors, handleChange }: NewsletterSubscriptionProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'PriceInformation.Configurators.Newsletter' });

  return (
    <Container>
      <Field direction="column">
        <label htmlFor="titleId" className="form-label">
          <span>{t('CustomerTitle')}</span>
        </label>
        <Flex className="position-relative mt-1">
          <ErrorHandledRadio
            error={(!!errors.newsletterTitle).toString()}
            name="newsletterTitle"
            inline
            type="radio"
            id={`id-${t('Miss')}`}
            label={t('Miss')}
            value={Title.Miss}
            checked={Number(values.newsletterTitle) === Title.Miss}
            onChange={handleChange}
          />
          <ErrorHandledRadio
            error={(!!errors.newsletterTitle).toString()}
            name="newsletterTitle"
            inline
            type="radio"
            id={`id-${t('Mister')}`}
            label={t('Mister')}
            value={Title.Mister}
            checked={Number(values.newsletterTitle) === Title.Mister}
            onChange={handleChange}
          />

          <div className={`invalid-tooltip ${errors.newsletterTitle && 'd-block'}`}>{errors.newsletterTitle}</div>
        </Flex>
      </Field>

      <Field direction="column">
        <label htmlFor="nameId" className="form-label">
          <span>{t('Name')}</span>
        </label>
        <div className="position-relative">
          <input
            id="nameId"
            className={`form-control ${!!errors.newsletterName && 'is-invalid'}`}
            name="newsletterName"
            value={values.newsletterName}
            onChange={handleChange}
          />

          <div className="invalid-tooltip">{errors.newsletterName}</div>
        </div>
      </Field>

      <Field direction="column">
        <label htmlFor="surnameId" className="form-label">
          <span>{t('Surname')}</span>
        </label>
        <div className="position-relative">
          <input
            id="surnameId"
            className={`form-control ${!!errors.newsletterSurname && 'is-invalid'}`}
            name="newsletterSurname"
            value={values.newsletterSurname}
            onChange={handleChange}
          />

          <div className="invalid-tooltip">{errors.newsletterSurname}</div>
        </div>
      </Field>
    </Container>
  );
}
