import { IAppConfiguration, ProductType } from '../../models';
import BaseService from '../BaseService';

/**
 *
 * This is the interface that the class ProductService
 * is going to implement.
 * A hook is then going to be used to retrieve an instance
 * to be used in the Components whenever needed.
 *
 */
export interface IProductService {
  getProductTypeByName(name: string): Promise<ProductType>;
}

/**
 *
 * This is the class that implements the IProductService interface.
 * Make use of the related hook to use it in the Components.
 *
 */
export default class ProductService extends BaseService implements IProductService {
  constructor(config: IAppConfiguration) {
    super(config, '/product');
  }

  async getProductTypeByName(name: string): Promise<ProductType> {
    return await this.get<ProductType>(`/producttype/${encodeURIComponent(encodeURIComponent(name))}`);
  }
}
