import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DieselProductName,
  DiscountPhraseKeys,
  FuelProductName,
  GermanLanguageTag,
  ProductTemplateKeys,
  WoodProductName,
} from '../../assets/constants/Constants';
import { UnitCode } from '../../assets/enums';
import OrderStepperContext from '../../contexts/stepper/OrderStepperContext';
import {
  useApplicationStatus,
  useOffersService,
  useOrderFormContext,
  useProductService,
  useProductType,
  useShopService,
  useStepper,
  useSettingsService,
  usePostalCodeService,
} from '../../hooks';
import useCurrentLanguage from '../../hooks/useCurrentLanguage';
import useErrorHandling from '../../hooks/useErrorHandling';
import {
  GetOffersRequest,
  GetOffersResponse,
  isErrorViewModel,
  isPromotionErrorViewModel,
  OrderPhase,
  PromotionType,
  Product,
  Promotion,
} from '../../models';
import { Shop } from '../../models/offers-service/Shop';
import withErrorHandling from '../common/hoc/with-error-handling/withErrorHandling';
import roundPrice from '../../utils/roundPrice';
import convertToUnitCode from '../../utils/unitCode';
import ShopSelector from './shop-selector/ShopSelector';
import styled from 'styled-components';
import { PriceResponse } from '../../models/offers-service/PriceResponse';

import SearchIcon from '../../assets/magnifying-glass.svg';
import { SystemSettings } from '../../data/settings-service/SettingsService';

function replaceTemplateKey<Keys extends string>(stringToModify: string, key: Keys, value: string) {
  return stringToModify.replace(key, value);
}

/**
 * Style for the promotion code field layout.
 */
const PromotionCodeField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
  }
`;

/**
 * Style for the promotion code input.
 */
const PromotionCodeInput = styled.input`
  margin-bottom: 0.5rem;
  align-self: stretch;
  min-width: 200px;
  max-width: 50%;

  @media (min-width: 1200px) {
    max-width: 300px;
    align-self: auto;
    margin-right: 1rem;
    margin-bottom: 0;
  }
`;

/**
 * Responvise style for the products container.
 */
const ProductsWrapper = styled.div.attrs(() => ({
  className: 'mb-3',
}))<{}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;

  & > div {
    margin-bottom: 1rem;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

/**
 * Styles for the wrapper around the search another shop input area.
 */
const SearchAnotherShopInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  max-width: 576px;
`;

/**
 * This interface describes the shop contained in the grid for choosing another shop to order from.
 */
export interface GridShop {
  shop: Shop;
  shopPostalCodes: string[];
  shopLocation: string;
}

interface PromotionFeedback {
  code: string;
  error?: string;
}

/**
 * Keyed interface (productId) for inserting the promotion code
 * in case of multiple offers.
 */
interface PromotionCode {
  [key: number]: PromotionFeedback;
}

/**
 * Keyed interface (productId) for inserting the promotion code
 * in case of multiple offers.
 */
interface IsApplyingPromotionCode {
  [key: number]: boolean;
}

/**
 * The ProductSelection props.
 */
interface ProductSelectionProps {
  /**
   * Used to determine whether to go into the price notifications stepper
   * or the details one.
   */
  setPath: (path: OrderPhase) => void;
}

/**
 *
 * This Component represent the product selection step in the order process.
 * It also contains the shop selection part and the possibility to
 * subscribe to the notifications.
 *
 * @returns the Component to be used in JSXs.
 */
function ProductSelection({ setPath }: ProductSelectionProps) {
  const offersService = useOffersService();
  const productService = useProductService();
  const shopService = useShopService();
  const settingsService = useSettingsService();
  const postalCodeService = usePostalCodeService();

  const { t } = useTranslation();
  const language = useCurrentLanguage();

  const { errors, setErrors } = useErrorHandling();

  const {
    postalCode,
    quantity,
    setQuantity,
    unloadingPlaces,
    setUnloadingPlaces,
    setShop,
    setSelectedProduct,
    setPriceDetails,
    setSelectedQuantity,
    setSelectedUnloadingPlaces,
    unloadingPlaceItems,
    setUnloadingPlaceItems,
    setSelectedPromotion,
  } = useOrderFormContext();

  const { setApplicationStatus } = useApplicationStatus();

  const productTypeString = useProductType();

  const { nextStep, prevStep } = useStepper(OrderStepperContext)!;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isApplyingPromotionCode, setIsApplyingPromotionCode] = useState<IsApplyingPromotionCode>({});

  const [allOffers, setAllOffers] = useState<GetOffersResponse[]>();

  const [allShops, setAllShops] = useState<Shop[]>();
  const [selectedShop, setSelectedShop] = useState<Shop>();
  const [showSelectShop, setShowSelectShop] = useState<boolean>(false);
  const [systemInfo, setSystemInfo] = useState<SystemSettings>();

  const [promotionCode, setPromotionCode] = useState<PromotionCode>({});

  const [shopQuery, setShopQuery] = useState<string>('');

  const shopContainsInfo = useCallback((shop: Shop, query: string) => {
    const shopZoneNames = shop.zones
      .map((zone) => zone.name)
      .join(', ')
      .toLocaleLowerCase();
    const shopPostalCodes = shop.zones
      .flatMap((zone) => zone.postalCodes.map((postalCode) => postalCode.npa.toString()))
      .join(', ')
      .toLocaleLowerCase();

    return (
      shopZoneNames.includes(query) || shopPostalCodes.includes(query) || shop.name.toLocaleLowerCase().includes(query)
    );
  }, []);

  const gridShops = useMemo(() => {
    return (
      allShops
        ?.filter((shop) => shopContainsInfo(shop, shopQuery))
        .map<GridShop>((shop) => ({
          shop: shop,
          shopPostalCodes: shop.zones.flatMap((zone) =>
            zone.postalCodes.map((postalCode) => postalCode.npa.toString()),
          ),
          shopLocation: shop.address,
        })) ?? []
    );
  }, [allShops, shopContainsInfo, shopQuery]);

  const composeDiscountPhrase = useCallback(
    (
      raw: string,
      priceOffer: Pick<PriceResponse, 'promotion' | 'totalDiscount' | 'unitDiscount' | 'outputUnitCode'>,
    ) => {
      let compiledPhrase = raw;

      compiledPhrase = replaceTemplateKey<DiscountPhraseKeys>(
        compiledPhrase,
        '{{CHFAmountTotal}}',
        roundPrice(priceOffer.totalDiscount),
      );
      compiledPhrase = replaceTemplateKey<DiscountPhraseKeys>(
        compiledPhrase,
        '{{CHFAmount}}',
        roundPrice(priceOffer.unitDiscount),
      );
      compiledPhrase = replaceTemplateKey<DiscountPhraseKeys>(
        compiledPhrase,
        '{{EmailContribution}}',
        language === GermanLanguageTag
          ? priceOffer.promotion?.emailContributionDE ?? ''
          : priceOffer.promotion?.emailContributionFR ?? '',
      );
      compiledPhrase = replaceTemplateKey<DiscountPhraseKeys>(
        compiledPhrase,
        '{{MinimalQuantity}}',
        convertToUnitCode(priceOffer.promotion?.minimalQuantity ?? 0, priceOffer.outputUnitCode).toString(),
      );
      compiledPhrase = replaceTemplateKey<DiscountPhraseKeys>(
        compiledPhrase,
        '{{PromotionName}}',
        priceOffer.promotion?.internalName ?? '',
      );

      return compiledPhrase;
    },
    [language, GermanLanguageTag, replaceTemplateKey, roundPrice],
  );

  const composeQuantityBasedDiscountPhrase = useCallback(
    (priceOffer: Pick<PriceResponse, 'promotion' | 'unitDiscount' | 'totalDiscount' | 'outputUnitCode'>) => {
      if (!priceOffer.promotion) {
        return '';
      }

      let compiledPhrase = `-{{${
        priceOffer.promotion.promotionType === PromotionType.AmountByQuantity ||
        priceOffer.promotion.promotionType === PromotionType.AmountByQuantityWithCode ||
        priceOffer.promotion.promotionType === PromotionType.Percentual ||
        priceOffer.promotion.promotionType === PromotionType.PercentualWithCode ||
        priceOffer.promotion.promotionType === PromotionType.FinalFixedPrice ||
        priceOffer.promotion.promotionType === PromotionType.FinalFixedPriceWithCode
          ? 'CHFAmount'
          : 'CHFAmountTotal'
      }}} CHF`;

      if (
        priceOffer.promotion?.promotionType === PromotionType.AmountByQuantity ||
        priceOffer.promotion?.promotionType === PromotionType.AmountByQuantityWithCode
      ) {
        compiledPhrase += ' / ';

        switch (priceOffer.outputUnitCode) {
          case UnitCode.hl:
            compiledPhrase += `100${t('Liters')}`;

            break;
          case UnitCode.t:
            compiledPhrase += `1000${t('Kilograms')}`;

            break;
          case UnitCode.kg:
            compiledPhrase += `1${t('Kilograms')}`;

            break;
          case UnitCode.lt:
            compiledPhrase += `1${t('Liters')}`;

            break;
          default:
            console.error("Unexpected output unit code. Couldn't determine the unit discount.");

            return '';
        }
      }

      compiledPhrase += ' {{PromotionName}}';

      compiledPhrase = replaceTemplateKey<DiscountPhraseKeys>(
        compiledPhrase,
        '{{CHFAmountTotal}}',
        roundPrice(priceOffer.totalDiscount),
      );
      compiledPhrase = replaceTemplateKey<DiscountPhraseKeys>(
        compiledPhrase,
        '{{CHFAmount}}',
        roundPrice(priceOffer.unitDiscount),
      );
      compiledPhrase = replaceTemplateKey<DiscountPhraseKeys>(
        compiledPhrase,
        '{{PromotionName}}',
        priceOffer.promotion?.internalName ?? '',
      );

      return compiledPhrase;
    },
    [t, replaceTemplateKey, roundPrice],
  );

  const injectTemplateValues = useCallback(
    (productTemplate: string, image: string | undefined, priceOffer: PriceResponse) => {
      let compiledTemplate = productTemplate;

      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{basePrice}}',
        roundPrice(priceOffer.basePrice),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{discount}}',
        composeDiscountPhrase(
          language === GermanLanguageTag
            ? priceOffer.promotion?.descriptionDE ?? ''
            : priceOffer.promotion?.descriptionFR ?? '',
          priceOffer,
        ),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{quantityBasedDiscount}}',
        composeQuantityBasedDiscountPhrase(priceOffer),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{outputUnitCode}}',
        priceOffer.outputUnitCode.toString(),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{totalDiscount}}',
        roundPrice(priceOffer.totalDiscount),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{totalMargin}}',
        roundPrice(priceOffer.totalMargin),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{totalPriceDiscounted}}',
        roundPrice(priceOffer.totalPriceDiscounted),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{totalPrice}}',
        roundPrice(priceOffer.totalPrice),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{totalUnloadingFee}}',
        roundPrice(priceOffer.totalUnloadingFee),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{totalVat}}',
        roundPrice(priceOffer.totalVat),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{unitDiscount}}',
        roundPrice(priceOffer.unitDiscount),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{unitMargin}}',
        roundPrice(priceOffer.unitMargin),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{unitPriceDiscounted}}',
        priceOffer.promotion
          ? `${roundPrice(priceOffer.unitPriceDiscounted)} CHF ${t('ProductSelection.ProductTemplate.DiscountedPrice')}`
          : '',
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{unitPrice}}',
        roundPrice(priceOffer.unitPrice),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{unitVat}}',
        roundPrice(priceOffer.unitVat),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{unloadingFee}}',
        roundPrice(priceOffer.unloadingFee),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{unloadingPlacesCount}}',
        priceOffer.unloadingPlacesCount.toString(),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{vatRate}}',
        roundPrice(priceOffer.vatRate),
      );
      const usedQuantity = convertToUnitCode(priceOffer.productQuantity, priceOffer.outputUnitCode);
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(
        compiledTemplate,
        '{{quantity}}',
        usedQuantity.toString(),
      );
      compiledTemplate = replaceTemplateKey<ProductTemplateKeys>(compiledTemplate, '{{image}}', image || '');

      return compiledTemplate;
    },
    [],
  );

  const chooseCorrectTemplate = useCallback(
    (product: Product, promotion?: Promotion) => {
      return (
        promotion?.promotionTemplates?.find(
          (promotionTemplate) =>
            promotionTemplate.language === language.toUpperCase() &&
            (promotionTemplate.productId == product.id || promotionTemplate.productId === null),
        )?.template ??
        product.productTemplate.find((template) => template.language === language.toUpperCase())?.template ??
        ''
      );
    },
    [language],
  );

  const chooseCorrectTemplateImage = useCallback(
    (product: Product, promotion?: Promotion) => {
      return (
        promotion?.promotionTemplates?.find(
          (promotionTemplate) =>
            promotionTemplate.language === language.toUpperCase() && promotionTemplate.productId == product.id,
        )?.templateImageUrl ??
        promotion?.promotionTemplateImageUrl ??
        product.productImageUrl
      );
    },
    [language],
  );

  const getProductType = useCallback(
    async () => await productService.getProductTypeByName(productTypeString),
    [productService, productTypeString],
  );

  const retrieveAllOffers = useCallback(
    async (productTypeId: number, promotionCode?: string, productId?: number) => {
      const tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);

      const getOffersRequest: GetOffersRequest = {
        deliveryDate: tomorrow,
        postalCode: postalCode!.npa.toString(),
        name: postalCode?.name?.toString(),
        productQuantity: quantity,
        productTypeId: productTypeId,
        unitCode:
          productTypeString === FuelProductName
            ? UnitCode.lt
            : productTypeString === DieselProductName
            ? UnitCode.lt
            : UnitCode.kg,
        unloadingPlaces: unloadingPlaces,
        shopId: selectedShop?.id,
        outputUnitCode:
          productTypeString === FuelProductName
            ? UnitCode.hl
            : productTypeString === DieselProductName
            ? UnitCode.hl
            : UnitCode.t,
        unloadingPlaceItems: unloadingPlaceItems,
      };

      if (promotionCode) {
        getOffersRequest.discountCode = promotionCode;
        getOffersRequest.productId = productId;
      }

      return await offersService.getOffers(getOffersRequest);
    },
    [offersService, postalCode, quantity, productTypeString, unloadingPlaces, selectedShop, unloadingPlaceItems],
  );

  const retrieveAllShops = useCallback(
    async (productTypeId: number) => await shopService.getAllSelectableShops(productTypeId),
    [shopService],
  );

  useEffect(() => {
    settingsService.getMaxFuelQuantity().then((settingValue) => {
      if (quantity > settingValue && productTypeString === FuelProductName) {
        prevStep();
      }
    });

    settingsService.getMaxWoodQuantity().then((settingValue) => {
      if (quantity > settingValue && productTypeString === WoodProductName) {
        prevStep();
      }
    });

    settingsService.getMaxDieselQuantity().then((settingValue) => {
      if (quantity > settingValue && productTypeString === DieselProductName) {
        prevStep();
      }
    });
  }, []);

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);

      const systemInfo = await settingsService.getSystemInfo(productTypeString, language, t);
      setSystemInfo(systemInfo);
      if (!systemInfo || !systemInfo.isSystemOnline) {
        setIsLoading(false);
        return;
      }

      try {
        const productType = await getProductType();

        const allOffers = await retrieveAllOffers(productType.id);
        const allSelectableShops = await retrieveAllShops(productType.id);
        setAllOffers(allOffers);
        setAllShops(allSelectableShops);
      } catch (error) {
        if (error instanceof Error) {
          setErrors([
            ...errors,
            {
              statusCode: '',
              title: error.message,
              value: {
                description: error.message,
              },
            },
          ]);
        }
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    async function retrieveOffersWithLoading() {
      setIsLoading(true);

      try {
        const productType = await getProductType();
        const allOffers = await retrieveAllOffers(productType.id);

        setAllOffers(allOffers);
      } catch (error) {
        if (isPromotionErrorViewModel(error)) {
          setErrors([
            ...errors,
            {
              statusCode: '',
              title: '',
              value: { description: t(`ProductSelection.PromotionCodeErrors.${error.value!.promotionErrorKey}`) },
            },
          ]);
        } else if (isErrorViewModel(error)) {
          setErrors([...errors, error]);
        } else {
          const castedError = error as Error;

          setErrors([
            ...errors,
            {
              statusCode: '',
              title: castedError.message,
              value: {
                description: castedError.message,
              },
            },
          ]);
        }
      } finally {
        setIsLoading(false);
      }
    }

    if (selectedShop) {
      retrieveOffersWithLoading();
    }
  }, [selectedShop]);

  if (isLoading) {
    return (
      <div className="mt-2 d-flex justify-content-start">
        <div className="spinner-border spinner-border" role="status">
          <span className="visually-hidden">{t('Loading')}</span>
        </div>
      </div>
    );
  }

  if (!systemInfo || !systemInfo.isSystemOnline) {
    const innerHtml = { __html: systemInfo?.message ?? '' };
    return <span className="mt-2 d-block text-danger fw-bold" dangerouslySetInnerHTML={innerHtml} />;
  }

  if (!allOffers || (allOffers.length === 0 && !selectedShop)) {
    return <span className="mt-2 d-block fw-bold">{t('ProductSelection.NoOffers')}</span>;
  }

  if (allOffers.length === 0 && selectedShop) {
    return (
      <div className="mt-2 d-flex container-fluid flex-column p-0">
        <div className="mb-4">
          <span className="d-block mb-2 text-danger fw-bold">{t('ProductSelection.ShopNoOffers')}</span>
          <ShopSelector
            shops={gridShops}
            selectedShop={selectedShop}
            setSelectedShop={(shop) => setSelectedShop(shop)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="mt-2 d-flex container-fluid flex-column p-0">
      <h2 style={{ marginBottom: '0.25rem' }}>{t('ProductSelection.Supplier')}</h2>
      <p>{t('ProductSelection.OffersHint')}</p>

      <div className="mb-4">
        <span className="mb-1" style={{ display: 'block' }}>
          {t('ProductSelection.ProposedSupplier')}
        </span>
        <div>
          <pre style={{ fontFamily: 'inherit', fontSize: '1rem' }}>
            {selectedShop?.address ?? allOffers[0].shop.address}
          </pre>
        </div>

        <label htmlFor="searchAnotherShopId" className="form-label fw-bold">
          {t('ProductSelection.SelectAnotherShop')}
        </label>
        <SearchAnotherShopInput>
          <input
            id="searchAnotherShopId"
            type="text"
            className="form-control me-2"
            value={shopQuery}
            onChange={(e) => setShopQuery(e.currentTarget.value)}
          />
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShowSelectShop(!showSelectShop)}
            style={{ display: 'inline-flex', alignItems: 'center', alignSelf: 'stretch' }}
          >
            <img src={SearchIcon} alt={t('ProductSelection.SearchAnotherShop')} style={{ height: 22 }} />
          </button>
        </SearchAnotherShopInput>

        {showSelectShop && (
          <ShopSelector
            shops={gridShops}
            selectedShop={selectedShop ?? allOffers[0].shop}
            setSelectedShop={(shop) => {
              setSelectedShop(shop);
              setShowSelectShop(false);
            }}
          />
        )}
      </div>

      <h2 style={{ marginBottom: '0.5rem' }}>{t('ProductSelection.Offers')}</h2>
      <ProductsWrapper>
        {allOffers.map((offer, index) => (
          <div
            key={index}
            className={`d-flex flex-column align-items-start justify-content-start p-2 border`}
            style={{ flex: 1, minWidth: '32vw', borderCollapse: 'collapse' }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: injectTemplateValues(
                  chooseCorrectTemplate(offer.product, offer.priceOffer.promotion),
                  chooseCorrectTemplateImage(offer.product, offer.priceOffer.promotion),
                  offer.priceOffer,
                ),
              }}
            />

            <button
              type="button"
              className="btn btn-primary mt-1"
              onClick={() => {
                settingsService.getSystemInfo(productTypeString, language, t).then((systemInformation) => {
                  if (!systemInformation.isSystemOnline) {
                    prevStep();
                    return;
                  }

                  const path = OrderPhase.details;
                  const finalShop = selectedShop ?? allOffers[0].shop;
                  setPath(path);
                  setSelectedProduct(offer.product);
                  setShop(finalShop);
                  setPriceDetails(offer.priceOffer);
                  setSelectedPromotion(offer.priceOffer.promotion);

                  const usedQuantity = convertToUnitCode(
                    offer.priceOffer.productQuantity,
                    offer.priceOffer.outputUnitCode,
                  );
                  setQuantity(usedQuantity);
                  setSelectedQuantity(usedQuantity);

                  setUnloadingPlaces(offer.priceOffer.unloadingPlacesCount);
                  setSelectedUnloadingPlaces(offer.priceOffer.unloadingPlacesCount);

                  setApplicationStatus({
                    path: path,
                    shop: finalShop,
                    selectedProduct: offer.product,
                    priceDetails: offer.priceOffer,
                    quantity: usedQuantity,
                    unloadingPlaces: offer.priceOffer.unloadingPlacesCount,
                    selectedQuantity: usedQuantity,
                    selectedUnloadingPlaces: offer.priceOffer.unloadingPlacesCount,
                  });

                  nextStep();
                });
              }}
            >
              {t('ProductSelection.OrderNow') + ' >'}
            </button>

            <div className="mt-4 align-self-stretch">
              {!!promotionCode[offer.productId]?.error && (
                <span className="d-block text-danger">{promotionCode[offer.productId].error}</span>
              )}
              <label htmlFor="promotionCodeId" className="form-label">
                {t('ProductSelection.PromotionCode')}
              </label>
              <PromotionCodeField>
                <PromotionCodeInput
                  id="promotionCodeId"
                  type="text"
                  placeholder={t('ProductSelection.PromotionCodeHint')}
                  className="form-control"
                  value={promotionCode[offer.productId]?.code ?? ''}
                  onChange={(e) => {
                    const productPromotionDetails: PromotionFeedback = promotionCode[offer.productId]
                      ? { ...promotionCode[offer.productId] }
                      : { code: '' };
                    const newInputCodes: PromotionCode = {
                      ...promotionCode,
                      [offer.productId]: { ...productPromotionDetails, code: e.currentTarget.value },
                    };

                    setPromotionCode(newInputCodes);
                    setSelectedPromotion(offer.priceOffer.promotion);
                  }}
                />
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ whiteSpace: 'nowrap' }}
                  disabled={!!isApplyingPromotionCode[offer.productId] || !promotionCode[offer.productId]?.code}
                  onClick={async () => {
                    setIsApplyingPromotionCode({ ...isApplyingPromotionCode, [offer.productId]: true });

                    let newInputCodes: PromotionCode = {
                      ...promotionCode,
                    };

                    try {
                      const offersWithPromotionApplied = await retrieveAllOffers(
                        offer.product.productType.id,
                        promotionCode[offer.productId]?.code,
                        offer.productId,
                      );

                      newInputCodes = {
                        ...newInputCodes,
                        [offer.productId]: { ...newInputCodes[offer.productId], error: '' },
                      };

                      setAllOffers(offersWithPromotionApplied);
                    } catch (error) {
                      if (isPromotionErrorViewModel(error)) {
                        newInputCodes = {
                          ...newInputCodes,
                          [offer.productId]: {
                            ...newInputCodes[offer.productId],
                            error: t(`ProductSelection.PromotionCodeErrors.${error.value!.promotionErrorKey}`, {
                              code: promotionCode[offer.productId]?.code,
                            }),
                          },
                        };
                      } else if (isErrorViewModel(error)) {
                        setErrors([...errors, error]);
                      } else {
                        const castedError = error as Error;

                        setErrors([
                          ...errors,
                          {
                            statusCode: '',
                            title: castedError.message,
                            value: {
                              description: castedError.message,
                            },
                          },
                        ]);
                      }
                    } finally {
                      const productPromotionDetails: PromotionFeedback = newInputCodes[offer.productId]
                        ? { ...newInputCodes[offer.productId], code: '' }
                        : { code: '' };

                      newInputCodes = { ...newInputCodes, [offer.productId]: productPromotionDetails };

                      setPromotionCode(newInputCodes);
                      setSelectedPromotion(offer.priceOffer.promotion);
                      setIsApplyingPromotionCode({ ...isApplyingPromotionCode, [offer.productId]: false });
                    }
                  }}
                >
                  {isApplyingPromotionCode[offer.productId] ? (
                    <div className="spinner-border spinner-border-sm text-light" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    t('ProductSelection.RecalculatePrice')
                  )}
                </button>
              </PromotionCodeField>
            </div>

            {productTypeString === FuelProductName && (
              <>
                <span className="mb-1 mt-4">{t('ProductSelection.DoYouWantToOrderLater')}</span>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    settingsService.getSystemInfo(productTypeString, language, t).then((systemInformation) => {
                      if (!systemInformation.isSystemOnline) {
                        prevStep();

                        return;
                      }

                      const path = OrderPhase.priceNotifications;
                      const finalShop = selectedShop ?? allOffers[0].shop;
                      setPath(path);
                      setSelectedProduct(offer.product);
                      setShop(finalShop);
                      setPriceDetails(offer.priceOffer);

                      const usedQuantity = convertToUnitCode(
                        offer.priceOffer.productQuantity,
                        offer.priceOffer.outputUnitCode,
                      );
                      setQuantity(usedQuantity);
                      setSelectedQuantity(usedQuantity);

                      setUnloadingPlaces(offer.priceOffer.unloadingPlacesCount);
                      setSelectedUnloadingPlaces(offer.priceOffer.unloadingPlacesCount);

                      setApplicationStatus({
                        path: path,
                        shop: finalShop,
                        selectedProduct: offer.product,
                        priceDetails: offer.priceOffer,
                        quantity: usedQuantity,
                        unloadingPlaces: offer.priceOffer.unloadingPlacesCount,
                        selectedQuantity: usedQuantity,
                        selectedUnloadingPlaces: offer.priceOffer.unloadingPlacesCount,
                      });
                      nextStep();
                    });
                  }}
                >
                  {t('ProductSelection.ConfigurePriceNotification')}
                </button>
              </>
            )}
          </div>
        ))}
      </ProductsWrapper>
    </div>
  );
}

export default withErrorHandling(ProductSelection);
