import { useContext, useMemo } from 'react';
import OrderService, { IOrderService } from '../../data/orders-service/OrderService';
import AppConfigurationContext from '../../contexts/application/AppConfigurationContext';

/**
 *
 * This hook gets an instance of the OrderService class
 * to be used in a Component.
 *
 * @returns an IOrderService interface implemented by
 * an instance of the OrderService class.
 */
export function useOrderService(): IOrderService {
  const appConfig = useContext(AppConfigurationContext)!;

  const orderService = useMemo(() => new OrderService(appConfig), []);

  return orderService;
}
