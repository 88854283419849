import { Flex } from '../../../../../styled-components';
import { useTranslation } from 'react-i18next';
import { PriceInformationFormValues } from '../PriceInformationStep';
import styled from 'styled-components';
import { Interval } from '../../../../../contexts';

/**
 * The type for the "values" Component prop.
 * This higher order type is made in order not to tie the Component down to the
 * Formik implementation of the "values" object.
 */
type DesiredPriceFormValues = {
  [key in keyof Pick<PriceInformationFormValues, 'desiredPrice' | 'desiredPriceExpiration'>]:
    | number
    | string
    | undefined;
};

/**
 * The type for the "errors" Component prop.
 * This higher order type is made in order not to tie the Component down to the
 * Formik implementation of the "errors" object.
 */
type DesiredPriceFormErrors = {
  [key in keyof Pick<PriceInformationFormValues, 'desiredPrice' | 'desiredPriceExpiration'>]: string | undefined;
};

/**
 * Container for the fields.
 * Useful for the responsiveness of the Component.
 */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: 576px) {
    flex-direction: row;
  }
`;

/**
 * The label + input + tag.
 */
const Field = styled(Flex)`
  margin-right: 0;

  @media (min-width: 576px) {
    margin-right: 1.5rem;
  }
`;

/**
 * The DesiredPrice props.
 */
interface DesiredPriceProps {
  /**
   * The callback to pass to the inputs as provided by Formik.
   */
  handleChange: {
    /** Classic React change handler, keyed by input name */
    // eslint-disable-next-line
    (e: React.ChangeEvent<any>): void;
    /** Preact-like linkState. Will return a handleChange function.  */
    // eslint-disable-next-line
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void // eslint-disable-next-line
      : (e: string | React.ChangeEvent<any>) => void;
  };

  /**
   * The current price of the product.
   */
  actualPrice: number;

  /**
   * The values for the inputs.
   */
  values: DesiredPriceFormValues;

  /**
   * The possible errors for the form values.
   */
  errors: DesiredPriceFormErrors;
}

export default function DesiredPrice({ actualPrice, values, errors, handleChange }: DesiredPriceProps) {
  const { t } = useTranslation();

  return (
    <Container>
      <Field direction="column">
        <label htmlFor="actualPriceId" className="form-label">
          <span>{t('PriceInformation.Configurators.DesiredPrice.CurrentPrice')}</span>
        </label>
        <input id="actualPriceId" className="form-control" value={actualPrice} disabled />
        <p>
          <span className="d-block">{t('PriceInformation.Configurators.FuelUnitCode')}</span>
          <span className="d-block">{t('PriceInformation.Configurators.TaxInfo')}</span>
        </p>
      </Field>

      <Field direction="column">
        <label htmlFor="desiredPriceId" className="form-label">
          <span>{t('PriceInformation.Configurators.DesiredPrice.DesiredPrice')}</span>
        </label>
        <Flex direction="column" className="position-relative">
          <input
            id="desiredPriceId"
            className={`form-control ${!!errors.desiredPrice && 'is-invalid'}`}
            name="desiredPrice"
            value={values.desiredPrice}
            onChange={handleChange}
          />

          <div className="invalid-tooltip">{errors.desiredPrice}</div>
        </Flex>

        <p>
          <span className="d-block">{t('PriceInformation.Configurators.FuelUnitCode')}</span>
          <span className="d-block">{t('PriceInformation.Configurators.TaxInfo')}</span>
        </p>
      </Field>

      <Field direction="column">
        <label htmlFor="desiredPriceExpirationId" className="form-label">
          <span>{t('PriceInformation.Configurators.DesiredPrice.Expiration')}</span>
        </label>
        <Flex direction="column" className="position-relative">
          <select
            id="desiredPriceExpirationId"
            className={`form-select ${!!errors.desiredPriceExpiration && 'is-invalid'}`}
            name="desiredPriceExpiration"
            value={values.desiredPriceExpiration}
            onChange={handleChange}
          >
            <option value={Interval.ThreeDays}>{t('PriceInformation.Configurators.ThreeDays')}</option>
            <option value={Interval.SevenDays}>{t('PriceInformation.Configurators.SevenDays')}</option>
            <option value={Interval.TwoWeeks}>{t('PriceInformation.Configurators.TwoWeeks')}</option>
            <option value={Interval.FourWeeks}>{t('PriceInformation.Configurators.FourWeeks')}</option>
            <option value={Interval.EightWeeks}>{t('PriceInformation.Configurators.EightWeeks')}</option>
          </select>

          <div className="invalid-tooltip">{errors.desiredPriceExpiration}</div>
        </Flex>
      </Field>
    </Container>
  );
}
