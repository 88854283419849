import { useMemo } from 'react';
import styled from 'styled-components';
import { StepperContextValues } from '../../../contexts';
import { useStepper } from '../../../hooks';

/**
 * The responsive styles for the indicator description.
 */
const ResponsivePre = styled.pre`
  font-family: inherit;
  margin-bottom: 0.5em;

  @media (max-width: 768px) {
    display: none;
  }
`;

/**
 * The stepper timeline indicator props.
 */
export interface StepperIndicatorProps {
  /**
   * Current active step in the timeline.
   * Used to determine how to style the indicator.
   */
  activeStep: number;

  /**
   * The number to display inside the indicator.
   */
  stepNumber: number;

  /**
   * The text below the indicator.
   */
  description: string;

  /**
   * The context that wraps the parent stepper.
   */
  context: React.Context<StepperContextValues | undefined>;
}

/**
 *
 * The stepper timeline indicator.
 *
 * @returns the Component to be used in JSXs.
 */
export default function StepperIndicator({ activeStep, stepNumber, description, context }: StepperIndicatorProps) {
  const isTwoStepsPast = useMemo(() => stepNumber < activeStep, [stepNumber, activeStep]);
  const isPastStep = useMemo(() => stepNumber - 1 < activeStep, [stepNumber, activeStep]);
  const isCurrentStep = useMemo(() => stepNumber - 1 === activeStep, [stepNumber, activeStep]);

  const { stepsCount } = useStepper(context)!;

  const isLastStep = useMemo(() => stepNumber === stepsCount, [stepNumber, stepsCount]);

  return (
    <div
      className="d-flex align-items-stretch position-relative"
      style={{
        flex: 1,
        zIndex: stepsCount - stepNumber,
      }}
    >
      <div
        className="d-flex align-items-stretch"
        style={{
          flex: 1,
          filter:
            isCurrentStep || isPastStep
              ? `drop-shadow(1px 0px 0px #005c25) drop-shadow(0px 2px 0px #005c25) ${
                  stepNumber === 1 ? 'drop-shadow(-2px 0px 0px #005c25)' : ''
                } drop-shadow(0px -2px 0px #005c25)`
              : 'none',
        }}
      >
        <div
          className={`d-flex align-items-center ${isCurrentStep ? 'align-self-center' : ''}`}
          style={{
            flex: 1,
            padding: '0.5em',
            backgroundColor: isPastStep ? 'green' : '#f9f9f9',
            clipPath: 'polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)',
            borderTopLeftRadius: stepNumber === 1 ? '0.3rem' : 'none',
            borderBottomLeftRadius: stepNumber === 1 ? '0.3rem' : 'none',
            height: isCurrentStep ? '96%' : 'inherit',
          }}
        >
          <div
            className="d-flex flex-column align-items-center"
            style={{ flex: 1, color: isPastStep ? 'white' : 'black' }}
          >
            <span className={isCurrentStep ? 'fw-bold' : 'fw-normal'}>
              {stepNumber}.<ResponsivePre>{description}</ResponsivePre>
            </span>
          </div>
        </div>
      </div>

      {!isLastStep && (
        <div
          className="d-flex align-self-center position-absolute"
          style={{
            backgroundColor: isTwoStepsPast ? 'green' : '#f9f9f9',
            zIndex: -1,
            filter: isPastStep ? `drop-shadow(0px 2px 0px #005c25) drop-shadow(0px -2px 0px #005c25)` : 'none',
            height: isTwoStepsPast ? '100%' : '96%',
            width: 'calc(100% + 1px)',
          }}
        />
      )}
    </div>
  );
}
