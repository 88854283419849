/**
 * Enum indicating the possibilities in the expiration dropdown.
 * The enum names are also used as key for translation.
 */
export enum Interval {
  OneDay = 1,
  ThreeDays = 3,
  SevenDays = 7,
  TwoWeeks = 14,
  FourWeeks = 28,
  EightWeeks = 56,
}
