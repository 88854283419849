import {
  useApplicationStatus,
  useOrderFormContext,
  useProductType,
  useSettingsService,
  useStepper,
} from '../../../../hooks';
import Step from '../../../stepper/step/Step';
import OrderDetailsStepperContext from '../../../../contexts/stepper/OrderDetailsStepperContext';
import { Flex } from '../../../../styled-components';
import { useTranslation } from 'react-i18next';
import StepHeader from '../../../common/step-header/StepHeader';
import { Formik, FormikErrors } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { object, string, array, number, bool } from 'yup';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import withErrorHandling from '../../../common/hoc/with-error-handling/withErrorHandling';
import { DeliveryAddress } from '../../../../contexts';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { DieselProductName, WoodProductName } from '../../../../assets/constants/Constants';

/**
 * Styles for the form layout.
 */
const Layout = styled(Flex)`
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: ${({ direction }) => direction};
  }
`;

/**
 * Styles for the text area form column.
 */
const TextAreaLayout = styled.div.attrs((attrs) => ({
  ...attrs,
}))<{}>`
  flex: 1;

  @media (min-width: 992px) {
    margin-left: 1.5rem;
  }
`;

/**
 * Styles for the left input section of the page with billing and personal data.
 */
const DeliveryAddressesFormSection = styled.div.attrs((attrs) => ({
  ...attrs,
}))<{}>`
  flex: 1;
  margin-right: 0;

  @media (min-width: 992px) {
    margin-right: 1.5rem;
  }
`;

/**
 * This interface describes the values that are input in this form.
 */
interface DeliveryAddressesFormValues {
  deliveryAddresses: DeliveryAddress[];
  deliveryQuantities: number[];
}

function DeliveryAddressesStep() {
  const { t } = useTranslation();

  const { setApplicationStatus } = useApplicationStatus();

  const settingsService = useSettingsService();
  const language = useCurrentLanguage();
  const productTypeString = useProductType();

  const {
    postalCode,
    selectedQuantity,
    selectedUnloadingPlaces,
    unloadingPlaceItems,

    remarks,
    setRemarks,

    invoiceAddressStreet,
    invoiceAddressNumber,
    invoiceAddressPostalCode,
    invoiceAddressLocation,

    deliveryAddresses,
    setDeliveryAddresses,

    isTimeoutReached,
  } = useOrderFormContext();

  const { prevStep, nextStep } = useStepper(OrderDetailsStepperContext)!;

  /**
   * Callback that performs validation and sets the input form data
   * in the application status.
   * If everything is validated and no errors arise, it goes onto the next step.
   */
  const submitAndNextStep = useCallback(
    (values: DeliveryAddressesFormValues) => {
      settingsService.getSystemInfo(productTypeString, language, t).then((systemInfo) => {
        if (!systemInfo.isSystemOnline) {
          prevStep();

          return;
        }

        const newValues = {
          deliveryAddresses: values.deliveryAddresses,
          remarks: remarks,
        };

        if (selectedUnloadingPlaces == 1) {
          newValues.deliveryAddresses[0].quantity = selectedQuantity;
        } else {
          newValues.deliveryAddresses.forEach(
            (address, index) => (address.quantity = values.deliveryQuantities[index]),
          );
        }

        setDeliveryAddresses(newValues.deliveryAddresses);

        setApplicationStatus(newValues);

        nextStep();
      });
    },
    [remarks, selectedUnloadingPlaces, setDeliveryAddresses, setApplicationStatus, nextStep],
  );

  /**
   * Initial form values.
   * If delivery addresses have already been set, take those,
   * otherwise initialize with defaults.
   */
  const initialValues = useMemo<DeliveryAddressesFormValues>(() => {
    if (unloadingPlaceItems?.length > 0) {
      return {
        deliveryAddresses: unloadingPlaceItems.map<DeliveryAddress>((item, index) => ({
          street: '',
          civicNumber: '',
          postalCode: Number(item.npa),
          residence: item.name,
        })),
        deliveryQuantities: unloadingPlaceItems.map((item, index) => item.quantity),
      };
    } else {
      return {
        deliveryAddresses: Array.from({ length: 1 }).map<DeliveryAddress>((_item, index) => ({
          street:
            index === 0
              ? Number(postalCode!.npa) === Number(invoiceAddressPostalCode)
                ? invoiceAddressStreet
                : ''
              : '',
          civicNumber:
            index === 0
              ? Number(postalCode!.npa) === Number(invoiceAddressPostalCode)
                ? invoiceAddressNumber
                : ''
              : '',
          postalCode: index === 0 ? Number(postalCode!.npa) : Number(invoiceAddressPostalCode),
          residence: index === 0 ? postalCode!.name : invoiceAddressLocation,
        })),
        deliveryQuantities: [selectedQuantity],
      };
    }
  }, [
    selectedUnloadingPlaces,
    deliveryAddresses,
    invoiceAddressStreet,
    invoiceAddressNumber,
    invoiceAddressPostalCode,
    invoiceAddressLocation,
    postalCode,
    selectedQuantity,
    unloadingPlaceItems,
  ]);

  /**
   * The Yup validation schema for Formik.
   */
  const deliveryAddressesValidationSchema = useMemo(() => {
    return object().shape({
      deliveryAddresses: array().of(
        object().shape({
          street: string(),
          civicNumber: string(),
          postalCode: string()
            .matches(/^[0-9]+$/, t('PostalCodeTypeErrorMessage'))
            .min(4, t('PostalCodeTooShortErrorMessage'))
            .max(4, t('PostalCodeTooLongErrorMessage'))
            .required(),
          residence: string(),
          fillTank: bool().required(t('DeliveryAddresses.ValidationErrors.FillTankRequiredErrorMessage')),
        }),
      ),
      deliveryQuantities: array().of(
        number()
          .typeError(t('DeliveryAddresses.ValidationErrors.QuantityTypeError'))
          .min(1, t('DeliveryAddresses.ValidationErrors.LowCapQuantity')),
      ),
    });
  }, [selectedQuantity, t]);

  useEffect(() => {
    async function init() {
      const systemInfo = await settingsService.getSystemInfo(productTypeString, language, t);
      if (!systemInfo || !systemInfo.isSystemOnline) {
        prevStep();
      }

      if (isTimeoutReached()) {
        prevStep();

        return;
      }
    }

    init();
  }, []);

  return (
    <Step>
      <Flex direction="column" className="justify-content-between align-items-start">
        <StepHeader />

        <Formik
          onSubmit={submitAndNextStep}
          initialValues={initialValues}
          validationSchema={deliveryAddressesValidationSchema}
        >
          {({ handleSubmit, handleChange, handleBlur, setFieldValue, values, errors }) => {
            return (
              <Form noValidate onSubmit={handleSubmit} className="align-self-stretch">
                <Layout direction="row" style={{ justifyContent: 'space-between' }}>
                  <DeliveryAddressesFormSection className="d-flex flex-column">
                    {values.deliveryAddresses.map((deliveryAddress, index) => (
                      <div key={index}>
                        {unloadingPlaceItems.length > 1 && (
                          <span className="fw-bold h5 mb-1">
                            {t('DeliveryAddresses.DeliveryAddress') + ' ' + (index + 1)}
                          </span>
                        )}
                        <div className="d-flex mb-3 align-items-end">
                          <div className="me-3" style={{ flex: 0.7 }}>
                            <label htmlFor={'streetId' + index} className="mb-2">
                              {t('PersonalData.Street')}
                            </label>
                            <input
                              id={'streetId' + index}
                              name={`deliveryAddresses[${index}].street`}
                              className="form-control"
                              value={deliveryAddress.street}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>

                          <div className="ms-3" style={{ flex: 0.3 }}>
                            <label htmlFor={'civicNumberId' + index} className="mb-2">
                              {t('PersonalData.CivicNumber')}
                            </label>
                            <input
                              id={'civicNumberId' + index}
                              name={`deliveryAddresses[${index}].civicNumber`}
                              className="form-control"
                              value={deliveryAddress.civicNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="d-flex mb-3 align-items-end">
                          <div className="me-3" style={{ flex: 0.7 }}>
                            {errors.deliveryQuantities && Array.isArray(errors.deliveryQuantities) ? (
                              errors.deliveryQuantities[index] && (
                                <span className="d-block text-danger">{errors.deliveryQuantities[index]}</span>
                              )
                            ) : (
                              <span className="d-block text-danger">{errors.deliveryQuantities}</span>
                            )}
                            <label htmlFor={'quantityId' + index} className="mb-2">
                              {t('Quantity')}
                            </label>
                            <input
                              id={'quantityId' + index}
                              name={`deliveryQuantities[${index}]`}
                              className="form-control"
                              value={values.deliveryQuantities[index] ?? 0}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled
                            />
                          </div>
                          <div className="ms-3" style={{ flex: 0.3 }}>
                            {errors.deliveryAddresses && errors.deliveryAddresses[index] && (
                              <span className="d-block text-danger">
                                {(errors.deliveryAddresses[index] as FormikErrors<DeliveryAddress>).fillTank}
                              </span>
                            )}
                            <label className="form-label">{t('DeliveryAddresses.FillTank')}</label>

                            <div className="mb-2">
                              <Form.Check
                                inline
                                type="radio"
                                id={`id-${t('Yes')}-${index}`}
                                label={t('Yes')}
                                value={'true'}
                                name={`deliveryAddresses[${index}].fillTank`}
                                checked={values.deliveryAddresses[index].fillTank === true}
                                onChange={(e) => {
                                  setFieldValue(
                                    `deliveryAddresses[${index}].fillTank`,
                                    e.currentTarget.value === 'true',
                                  );
                                }}
                              />
                              <Form.Check
                                inline
                                type="radio"
                                id={`id-${t('No')}-${index}`}
                                label={t('No')}
                                value={'false'}
                                name={`deliveryAddresses[${index}].fillTank`}
                                checked={values.deliveryAddresses[index].fillTank === false}
                                onChange={(e) => {
                                  setFieldValue(
                                    `deliveryAddresses[${index}].fillTank`,
                                    e.currentTarget.value === 'true',
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {productTypeString !== WoodProductName && (
                          <div className="d-flex mb-3 align-items-end">
                            <div style={{ flex: 1 }}>
                              {values.deliveryAddresses[index].fillTank !== undefined && (
                                <span className="mb-3 fs-6 fst-italic">
                                  <br />
                                  {values.deliveryAddresses[index].fillTank?.toString() === 'true'
                                    ? productTypeString === DieselProductName
                                      ? t('DeliveryAddresses.FillTankYesInfoDiesel')
                                      : t('DeliveryAddresses.FillTankYesInfo')
                                    : t('DeliveryAddresses.FillTankNoInfo')}
                                </span>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="d-flex mb-4 align-items-end">
                          <div className="me-3" style={{ flex: 0.3 }}>
                            {errors.deliveryAddresses && errors.deliveryAddresses[index] && (
                              <span className="d-block text-danger">
                                {(errors.deliveryAddresses[index] as FormikErrors<DeliveryAddress>).postalCode}
                              </span>
                            )}
                            <label htmlFor={'invoiceAddressPostalCodeId' + index} className="mb-2">
                              {t('PersonalData.PostalCode')}
                            </label>
                            <input
                              id={'invoiceAddressPostalCodeId' + index}
                              name={`deliveryAddresses[${index}].postalCode`}
                              className="form-control"
                              value={deliveryAddress.postalCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled
                            />
                          </div>

                          <div className="ms-3" style={{ flex: 0.7 }}>
                            <label htmlFor={'invoiceAddressResidenceId' + index} className="mb-2">
                              {t('PersonalData.Residence')}
                            </label>
                            <input
                              id={'invoiceAddressResidenceId' + index}
                              name={`deliveryAddresses[${index}].residence`}
                              className="form-control"
                              value={deliveryAddress.residence}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </DeliveryAddressesFormSection>

                  <TextAreaLayout>
                    <label className="form-label">{t('DeliveryDate.Remarks')}</label>
                    <textarea
                      rows={3}
                      className="form-control"
                      style={{ width: '100%' }}
                      value={remarks}
                      onChange={(e) => setRemarks(e.currentTarget.value)}
                    />
                  </TextAreaLayout>
                </Layout>

                <div className="d-flex mt-4">
                  <button className="btn btn-secondary me-1" type="button" onClick={() => prevStep()}>
                    <span>{'< ' + t('DeliveryDate.Back')}</span>
                  </button>
                  <button className="btn btn-primary ms-1" type="submit">
                    <span>{t('DeliveryDate.Next') + ' >'}</span>
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Flex>
    </Step>
  );
}

export default withErrorHandling(DeliveryAddressesStep);
