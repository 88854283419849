import { useContext, useMemo } from 'react';
import AppConfigurationContext from '../../contexts/application/AppConfigurationContext';
import ShopService, { IShopService } from '../../data/shop-service/ShopService';

/**
 *
 * This hook gets an instance of the ShopService class
 * to be used in a Component.
 *
 * @returns an IShopService interface implemented by
 * an instance of the ShopService class.
 */
export function useShopService(): IShopService {
  const appConfig = useContext(AppConfigurationContext)!;

  const shopService = useMemo(() => new ShopService(appConfig), []);

  return shopService;
}
