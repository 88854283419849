import { useContext, useMemo } from 'react';
import PostalCodeService, { IPostalCodeService } from '../../data/postal-code-service/PostalCodeService';
import AppConfigurationContext from '../../contexts/application/AppConfigurationContext';
import { IAppConfiguration } from '../../models';

/**
 *
 * This hook gets an instance of the PostalCodeService class
 * to be used in a Component.
 *
 * @returns an IPostalCodeService interface implemented by
 * an instance of the PostalCodeService class.
 */
export function usePostalCodeService(appConfig?: IAppConfiguration): IPostalCodeService {
  const contextConfig = useContext(AppConfigurationContext)!;

  const finalAppConfig = appConfig ?? contextConfig;

  const postalCodeService = useMemo(() => new PostalCodeService(finalAppConfig), [finalAppConfig]);

  return postalCodeService;
}
