import { useContext, useMemo } from 'react';
import AppConfigurationContext from '../../contexts/application/AppConfigurationContext';
import SettingsService, { ISettingsService } from '../../data/settings-service/SettingsService';

/**
 *
 * This hook gets an instance of the SettingsService class
 * to be used in a Component.
 *
 * @returns an ISettingsService interface implemented by
 * an instance of the SettingsService class.
 */
export function useSettingsService(): ISettingsService {
  const appConfig = useContext(AppConfigurationContext)!;

  const settingsService = useMemo(() => new SettingsService(appConfig), []);

  return settingsService;
}
