/**
 *
 * As indicated in the {@https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin#basic-usage official Microsoft documentation},
 * this .js file creates all the necessary basic components in order to use the Application Insights SDK throughout our components.
 *
 */

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: 'YOUR_INSTRUMENTATION_KEY_GOES_HERE',
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();

export { reactPlugin, appInsights };
