import moment from 'moment';
import { useCallback } from 'react';
import { useOrderFormContext } from '.';
import { OrderFormContextValues } from '../contexts/order-form/types/OrderFormContextValues';
import { IApplicationStatus } from '../models';
import { storage } from '../utils/storage';

/**
 *
 * This function removes undefined properties from an object.
 *
 * @param object - the object to clean up.
 */
// eslint-disable-next-line
function removedUndefinedProperties(object: any): void {
  Object.keys(object).forEach((key) => object[key] === undefined && delete object[key]);
}

/**
 *
 * This hook is made for those components who need to restore
 * their state based on the previous application status.
 * It offers two helper methods: getApplicationStatus & setApplicationStatus
 * for managing the application.
 *
 * @returns an object that contains the two helper methods.
 */
export function useApplicationStatus(): {
  getApplicationStatus: () => IApplicationStatus;
  setApplicationStatus: (appStatus: IApplicationStatus) => void;
  deleteApplicationStatus: <TShape extends keyof IApplicationStatus>(propertiesToRemove: Array<TShape>) => void;
} {
  const orderFormContext = useOrderFormContext();

  /**
   * Sets the application state in the session storage as a JSON.
   */
  const setApplicationStatus = useCallback((appStatus: IApplicationStatus) => {
    const previousState = storage.get<IApplicationStatus>('appStatus');

    /**
     * Makes sure that even if we're starting from a different step than the first one,
     * we save the state for the 3 fundamental fields for an order.
     */
    const contextState: Pick<
      OrderFormContextValues,
      | 'postalCode'
      | 'quantity'
      | 'unloadingPlaces'
      | 'selectedQuantity'
      | 'selectedUnloadingPlaces'
      | 'unloadingPlaceItems'
      | 'selectedPromotion'
    > = {
      postalCode: orderFormContext.postalCode,
      quantity: orderFormContext.quantity,
      selectedQuantity: orderFormContext.selectedQuantity,
      unloadingPlaces: orderFormContext.unloadingPlaces,
      selectedUnloadingPlaces: orderFormContext.selectedUnloadingPlaces,
      unloadingPlaceItems: orderFormContext.unloadingPlaceItems,
      selectedPromotion: orderFormContext.selectedPromotion,
    };

    removedUndefinedProperties(appStatus);

    const newStatus = {
      ...contextState,
      ...previousState,
      ...appStatus,
    };

    storage.set('appStatus', newStatus);
  }, []);

  /**
   * Sets the application state in the session storage as a JSON.
   */
  const deleteApplicationStatus = useCallback(
    <TShape extends keyof IApplicationStatus>(propertiesToRemove: Array<TShape>) => {
      const previousState = storage.get<IApplicationStatus>('appStatus');

      if (!!previousState) {
        for (const property of propertiesToRemove) {
          delete previousState[property];
        }

        const newStatus = {
          ...previousState,
        };

        storage.set('appStatus', newStatus);
      }
    },
    [],
  );

  /**
   * Gets the application state from the session storage.
   */
  const getApplicationStatus = useCallback(() => {
    const appStatus = storage.get<IApplicationStatus>('appStatus') ?? {};

    appStatus.deliveryDates = appStatus?.deliveryDates?.map((deliveryDate) => moment(deliveryDate).toDate());

    return appStatus;
  }, []);

  return { getApplicationStatus, setApplicationStatus, deleteApplicationStatus };
}
