import { PriceResponse } from '../../models/offers-service/PriceResponse';

/**
 * This constant represents the available short name for the chosen German language.
 */
export const GermanLanguageTag = 'de';

/**
 * This constant represents the available short name for the chosen French language.
 */
export const FrenchLanguageTag = 'fr';

/**
 * This constant represent the ProductName for pellets inside the database
 * used to differentiate between the various products regardless
 * of the localization.
 */
export const WoodProductName = 'Wood';

/**
 * Default orderable quantities of wood
 */
export const DefaultMaxWoodQuantity = 22000;
export const DefaultMinWoodQuantity = 500;

/**
 * This constant represent the ProductName for oil inside the database
 * used to differentiate between the various products regardless
 * of the localization.
 */
export const FuelProductName = 'Fuel';

/**
 * Default orderable quantities of Fuel
 */
export const DefaultMaxFuelQuantity = 11000;
export const DefaultMinFuelQuantity = 500;

/**
 * This constant represent the ProductName for diesel inside the database
 * used to differentiate between the various products regardless
 * of the localization.
 */
export const DieselProductName = 'Diesel';

/**
 * Default orderable quantities of Diesel
 */
export const DefaultMaxDieselQuantity = 11000;
export const DefaultMinDieselQuantity = 500;

/**
 * The timeout of a price offer, 900000ms = 15min
 */
export const OrderTimeoutInMs = 900000;

/**
 * The name for the postal code query param from the main webpage.
 */
export const postalCodeQueryParam = 'plz_ext';

/**
 * The name for the quantity query param from the main webpage.
 */
export const quantityQueryParam = 'menge_ext';

/**
 * The name for the unloading places count query param from the main webpage.
 */
export const unloadingPlacesCountQueryParam = 'abladestellen_ext';

/**
 * The name for the portal query param from the main webpage.
 */
export const portalQueryParam = 'portal';

/**
 * String literal type for product types.
 */
export type AvailableLanguages = typeof GermanLanguageTag | typeof FrenchLanguageTag;

/**
 * String literal type for product types.
 */
export type ProductTypes = typeof WoodProductName | typeof FuelProductName | typeof DieselProductName;

/**
 * Product template substitution keys.
 */
export type ProductTemplateKeys =
  | `{{${keyof (Omit<PriceResponse, 'discountedPrice' | 'productQuantity'> & {
      quantity: string;
      discount: string;
      image: string;
      quantityBasedDiscount: string;
    })}}}`;

/**
 * Supported placeholders for discount phrase.
 */
export type DiscountPhraseKeys =
  | '{{MinimalQuantity}}'
  | '{{CHFAmount}}'
  | '{{EmailContribution}}'
  | '{{PromotionName}}'
  | '{{CHFAmountTotal}}';
