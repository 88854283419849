import { Alert, IAppConfiguration } from '../../models';
import BaseService from '../BaseService';

/**
 * This is the interface that the class PriceApiService
 * is going to implement.
 * A hook is then going to be used to retrieve an instance
 * to be used in the components whenever needed.
 */
export interface IAlertService {
  setDesiredPrice(request: Alert): Promise<void>;
  setPriceAlarm(request: Alert): Promise<void>;
  setPriceSubscription(request: Alert): Promise<void>;
  setNewsletterSubscription(request: Alert): Promise<void>;
  deleteSubscriptionByInternalId(internalId: string): Promise<void>;
}

/**
 * This is the class that implements the IPriceApiService interface.
 * Make use of the related hook to use it in the Components.
 */
export default class AlertService extends BaseService implements IAlertService {
  constructor(config: IAppConfiguration) {
    super(config, '/alert');
  }

  async setDesiredPrice(request: Alert): Promise<void> {
    await this.post('/desired', request);
  }

  async setPriceAlarm(request: Alert): Promise<void> {
    await this.post('/alarm', request);
  }

  async setPriceSubscription(request: Alert): Promise<void> {
    await this.post('/subscription', request);
  }

  async setNewsletterSubscription(request: Alert): Promise<void> {
    await this.post('/newsletter', request);
  }

  async deleteSubscriptionByInternalId(internalId: string): Promise<void> {
    await this.delete(`/${internalId}`);
  }
}
