import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import detectorOptions from './assets/i18n/configurations/detectorOptions.json';
// import iframeDetector from './assets/i18n/iframeDetector';

import de from './assets/i18n/translations/de.json';
import fr from './assets/i18n/translations/fr.json';
// import en from './assets/i18n/translations/en.json';

const translations = { ...de, ...fr };

// const languageDetector = new LanguageDetector();
// languageDetector.addDetector(iframeDetector);

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    resources: translations,
    fallbackLng: 'de',
    debug: true,
    detection: detectorOptions,

    interpolation: {
      escapeValue: false, // not needed for react
    },
  });

export default i18n;
