import { useContext, useMemo } from 'react';
import AppConfigurationContext from '../../contexts/application/AppConfigurationContext';
import OffersService, { IOffersService } from '../../data/offers-service/OffersService';

/**
 *
 * This hook gets an instance of the OffersService class
 * to be used in a Component.
 *
 * @returns an IOffersService interface implemented by
 * an instance of the OffersService class.
 */
export function useOffersService(): IOffersService {
  const appConfig = useContext(AppConfigurationContext)!;

  const offersService = useMemo(() => new OffersService(appConfig), []);

  return offersService;
}
