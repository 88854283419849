import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import useCurrentLanguage from '../../../hooks/useCurrentLanguage';
import styled from 'styled-components';
import { GridShop } from '../ProductSelection';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';

import deKendoTranslations from '../../../assets/i18n/translations/kendo/de.json';
import frKendoTranslations from '../../../assets/i18n/translations/kendo/fr.json';
import { Shop } from '../../../models/offers-service/Shop';
loadMessages(deKendoTranslations, 'de');
loadMessages(frKendoTranslations, 'fr');

/**
 * Initial grid sorting.
 */
const initialSort: Array<SortDescriptor> = [{ field: 'shopName', dir: 'asc' }];

/**
 * The main grid responsive size.
 * Column and column filters have to be adjusted via scss and class name override.
 */
const ResponsiveGrid = styled(Grid)`
  width: 100%;

  @media (min-width: 992px) {
    width: 75%;
  }

  @media (min-width: 1400px) {
    width: 50%;
  }

  .k-grid-header {
    padding: 0;
  }

  .k-grid-container .k-grid-content.k-virtual-content {
    overflow: hidden;
  }
`;

/**
 * The ShopSelector props.
 */
interface ShopSelectorProps {
  /**
   * The shops to display.
   */
  shops: GridShop[];

  /**
   * The currently selected shop.
   */
  selectedShop: Shop;

  /**
   * Callback that sets the shop selected.
   */
  setSelectedShop: (shop: Shop) => void;
}

/**
 *
 * The shop selector Component.
 *
 * @returns the JSX to be used in other Components.
 */
export default function ShopSelector({ shops, selectedShop, setSelectedShop }: ShopSelectorProps) {
  const { t } = useTranslation();
  const language = useCurrentLanguage();

  const [sort, setSort] = useState(initialSort);

  return (
    <LocalizationProvider language={language}>
      <IntlProvider locale={language}>
        <ResponsiveGrid
          data={orderBy(shops, sort)}
          sortable
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
          style={{ margin: '0.5em 0' }}
        >
          <Column field="shop.name" title={t('ProductSelection.SelecteShopGrid.ColumnShop')} />
          <Column
            title={t('ProductSelection.SelecteShopGrid.ColumnActions')}
            width={language === 'de' ? 100 : 125}
            filterable={false}
            sortable={false}
            cell={(props: PropsWithChildren<GridCellProps>) => {
              return (
                <td
                  colSpan={props.colSpan}
                  role="gridcell"
                  aria-colindex={props.ariaColumnIndex}
                  data-grid-col-index={props.columnIndex}
                  style={{ textAlign: 'center' }}
                >
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => setSelectedShop((props.dataItem as GridShop).shop)}
                    disabled={(props.dataItem as GridShop).shop.id === selectedShop.id}
                  >
                    {t('ProductSelection.SelecteShopGrid.SelectButton')}
                  </button>
                </td>
              );
            }}
          />
        </ResponsiveGrid>
      </IntlProvider>
    </LocalizationProvider>
  );
}
