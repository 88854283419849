import { IAppConfiguration, SettingName, Settings, ShopSetting } from '../../models';
import BaseService from '../BaseService';
import {
  AvailableLanguages,
  DefaultMaxFuelQuantity,
  DefaultMaxWoodQuantity,
  DefaultMaxDieselQuantity,
  DefaultMinFuelQuantity,
  DefaultMinWoodQuantity,
  DefaultMinDieselQuantity,
  FuelProductName,
  WoodProductName,
  DieselProductName,
  GermanLanguageTag,
} from '../../assets/constants/Constants';

interface RawSettings {
  id: number;
  settingName: string;
  settingData: string;
  settingValue: string;
}

/**
 * Simplified version of the settings for UI display purposes.
 */
export interface SystemSettings {
  isSystemOnline: boolean;
  message: string;
}

function toCamelCase(_key, value: Object) {
  if (value && typeof value === 'object') {
    for (const k in value) {
      if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
        delete value[k];
      }
    }
  }

  return value;
}

/**
 * This is the interface that the class ProductService
 * is going to implement.
 * A hook is then going to be used to retrieve an instance
 * to be used in the Components whenever needed.
 */
export interface ISettingsService {
  getSystemInfo(name: string, language: AvailableLanguages, t: any): Promise<SystemSettings>;
  getSettingValue<RawSettings>(settingName: SettingName, defaultValue?: number): Promise<number>;
  getMaxWoodQuantity(): Promise<number>;
  getMaxFuelQuantity(): Promise<number>;
  getMaxDieselQuantity(): Promise<number>;
  getMinWoodQuantity(): Promise<number>;
  getMinFuelQuantity(): Promise<number>;
  getMinDieselQuantity(): Promise<number>;
}

/**
 * This is the class that implements the IProductService interface.
 * Make use of the related hook to use it in the Components.
 */
export default class SettingsService extends BaseService implements ISettingsService {
  constructor(config: IAppConfiguration) {
    super(config, '/settings');
  }

  private async getSettingsByName<TData>(name: SettingName): Promise<Settings<TData>> {
    const rawSettings = await this.get<RawSettings>(`/${SettingName[name]}`);

    return {
      id: rawSettings.id,
      settingName: rawSettings.settingName,
      settingData: JSON.parse(rawSettings.settingData, toCamelCase) as TData,
      settingValue: rawSettings.settingValue,
    };
  }

  public async getSettingValue<RawSettings>(settingName: SettingName, defaultValue = 0): Promise<number> {
    try {
      const settingValue = (await this.getSettingsByName<RawSettings>(settingName)).settingValue;

      if (!isNaN(parseInt(settingValue))) return Number(settingValue);
    } catch (error) {
      console.log('Error occured', error);
    }
    return defaultValue;
  }

  public async getSystemInfo(productTypeString: string, language: AvailableLanguages, t: any): Promise<SystemSettings> {
    const shopSettings = (await this.getSettingsByName<ShopSetting>(SettingName.CustomerShopSetting)).settingData;

    const systemInfo: SystemSettings = {
      isSystemOnline: false,
      message: t('ProductSelection.SystemSettings.DefaultOfflineMessage'),
    };

    if (shopSettings) {
      if (productTypeString === WoodProductName) {
        systemInfo.isSystemOnline = shopSettings.statusWood;
        systemInfo.message = language === GermanLanguageTag ? shopSettings.messageWoodDE : shopSettings.messageWoodFR;
      } else if (productTypeString === FuelProductName) {
        systemInfo.isSystemOnline = shopSettings.statusFuel;
        systemInfo.message = language === GermanLanguageTag ? shopSettings.messageFuelDE : shopSettings.messageFuelFR;
      } else if (productTypeString === DieselProductName) {
        systemInfo.isSystemOnline = shopSettings.statusDiesel;
        systemInfo.message =
          language === GermanLanguageTag ? shopSettings.messageDieselDE : shopSettings.messageDieselFR;
      }
    }

    return systemInfo;
  }

  public async getMaxFuelQuantity(): Promise<number> {
    return await this.getSettingValue(SettingName.MaxFuelQuantity, DefaultMaxFuelQuantity);
  }

  public async getMaxWoodQuantity(): Promise<number> {
    return await this.getSettingValue(SettingName.MaxWoodQuantity, DefaultMaxWoodQuantity);
  }

  public async getMaxDieselQuantity(): Promise<number> {
    return await this.getSettingValue(SettingName.MaxDieselQuantity, DefaultMaxDieselQuantity);
  }

  public async getMinWoodQuantity(): Promise<number> {
    return await this.getSettingValue(SettingName.MinWoodQuantity, DefaultMinWoodQuantity);
  }

  public async getMinFuelQuantity(): Promise<number> {
    return await this.getSettingValue(SettingName.MinFuelQuantity, DefaultMinFuelQuantity);
  }

  public async getMinDieselQuantity(): Promise<number> {
    return await this.getSettingValue(SettingName.MinDieselQuantity, DefaultMinDieselQuantity);
  }
}
