import moment from 'moment';

/**
 *
 * This function gets the current week number in the year.
 * e.g.: 1 for first week of January.
 *
 * @returns the week number in the year.
 */
export function getCurrentWeekNumberInYear() {
  return moment().isoWeek();
}

/**
 *
 * Gets how many weeks are in the specified year.
 *
 * @param year - The year we want to get the number of weeks from.
 *
 * @returns a number indicating how many weeks are in the specified year.
 */
export function howManyWeeksInYear(year: number) {
  return moment().year(year).isoWeeksInYear();
}

/**
 *
 * This function gets the week number in the year of a specific date.
 *
 * @param date - The date for which the week number in the year is needed.
 *
 * @returns the week number in the year of the input date.
 */
export function getWeekNumberInYear(date: Date) {
  return moment(date).isoWeek();
}
/**
 *
 * This function gets the month number in the year from a specified week number.
 * e.g.: passing week number 1 would retrieve month number 1, as it's the first week
 *       of January, month number 1.
 *
 * Month enumeration begins from 1 and not 0.
 *
 * @param weekNumber - The week number to begin the search from.
 *
 * @returns the month number.
 */
export function getMonthNumberFromWeekNumber(weekNumber: number) {
  const monday = moment()
    .startOf('year')
    .add(weekNumber - 1, 'weeks')
    .weekday(0)
    .date();

  const friday = moment()
    .startOf('year')
    .add(weekNumber - 1, 'weeks')
    .weekday(4)
    .date();

  if (Number(monday) > Number(friday)) {
    return (
      moment()
        .startOf('year')
        .add(weekNumber - 1, 'weeks')
        .weekday(0) // friday
        .month() + 1
    );
  }

  return (
    moment()
      .startOf('year')
      .add(weekNumber - 1, 'weeks')
      .weekday(4) // friday
      .month() + 1
  );
}
