import { UnitCode } from '../assets/enums';

export default function convertToUnicode(toConvert: number, unitCode: UnitCode): number {
  let result = toConvert;
  switch (unitCode) {
    case UnitCode.t:
      result = toConvert * 1000;
      break;
    case UnitCode.kg:
      result = toConvert * 1;
      break;
    case UnitCode.lt:
      result = toConvert * 1;
      break;
    case UnitCode.hl:
      result = toConvert * 100;
      break;

    default:
      console.warn("Couldn't determine the unit code to convert to. Returning untouched value.");
      return toConvert;
  }
  return Math.round(result);
}
