import { OrderStatus } from './OrderStatus';
import { UnitCode, Language } from '../../assets/enums';
import { Delivery } from './Delivery';
import { PriceResponse } from '../offers-service/PriceResponse';
import { PromotionGift } from '../offers-service/PromotionGift';

/**
 * Contains the additional properties of an order. They are separated
 * from the Order interface because they are so many.
 */
export interface OrderData {
  productId: number;

  status?: OrderStatus | null;

  externalOrderNumber?: string;

  shopId: number;

  /**
   * Contains the address of the shop that delivers the product.
   */
  shopContact: string;

  orderDate?: Date;

  timeStamp?: Date;

  invoiceSalutation: string;

  invoiceName: string;

  invoiceSurname: string;

  priceOffer: PriceResponse;

  invoiceCompany: string;

  invoiceStreet: string;

  invoiceHouseNumber: string;

  invoicePostalCode: string;

  invoiceLocation: string;

  deliveryAddresses: Array<Delivery>;

  productDescription: string;

  priceWithoutVAT?: number;

  priceWithVAT?: number;

  priceDiscountedWithVAT?: number;

  vat?: number;

  totalPriceWithoutVAT?: number;

  optionsVAT?: number;

  optionsWithVAT?: number;

  optionsWithoutVAT?: number;

  totalVAT?: number;

  totalPriceIncludingVAT?: number;

  totalPriceIncludingVATDiscounted?: number;

  email: string;

  phone: string;

  paymentType: string;

  fillTankCompletely?: boolean;

  bonus?: string;

  portalId: number;

  language: Language;

  ourComment?: string;

  clientComment: string;

  quantity: number;

  quantityUnit: UnitCode;

  unloadingPlacesCount: number;

  deliveryWeeks: Array<number>;

  storageRoomCleaning: boolean;

  smallVehicle: boolean;

  hoseLength: boolean;

  smallVehicleText?: string;

  storageRoomCleaningText?: string;

  hoseLengthText?: string;

  InvocationId?: string;

  promotionGift?: PromotionGift;
}

/**
 * Returns a translated string that prefixes the number of delivery weeks.
 */
// eslint-disable-next-line
export const getDeliveryWeeks = (t: any, deliveryWeeks: Array<number> | undefined): string => {
  if (deliveryWeeks == undefined || deliveryWeeks.length == 0) {
    return '-';
  }
  if (deliveryWeeks.length == 1) {
    return `${t('ReviewOrder.DeliveryWeekSingle')} ${deliveryWeeks[0]}`;
  } else {
    return `${t('ReviewOrder.DeliveryWeekPlural')} ${deliveryWeeks.join()}`;
  }
};
