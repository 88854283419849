/**
 * The view model for the errors that come from the server.
 */
export interface ErrorViewModel {
  title: string;
  statusCode: string;
  value?: ErrorViewModelValue | null;
}

export interface ErrorViewModelValue {
  description: string | null;
}

export interface PromotionErrorViewModel {
  title: string;
  statusCode: string;
  value?: PromotionErrorViewModelValue | null;
}

export interface PromotionErrorViewModelValue {
  promotionErrorKey: string;
}

/**
 * @param error Type guard for ErrorViewModel
 */
export function isErrorViewModel(error: unknown): error is ErrorViewModel {
  return (error as ErrorViewModel).title !== undefined || (error as ErrorViewModel).value !== undefined;
}

/**
 * @param error Type guard for PromotionErrorViewModel
 */
export function isPromotionErrorViewModel(error: unknown): error is PromotionErrorViewModel {
  return (error as PromotionErrorViewModel).value?.promotionErrorKey !== undefined;
}
