/**
 * Enum indicating in which phase the user is in the order
 * process.
 */
export enum OrderPhase {
  /**
   * Form indicates that the user is still in the first 2 steps
   * without the timeline.
   */
  form,

  /**
   * Details refers to the input details forms with the timeline.
   */
  details,

  /**
   * User decided to go into the price notifications stepper menu
   * and is not currently ordering a product, hence he's not in the details phase.
   */
  priceNotifications,
}
