import Stepper from '../../../../components/stepper/Stepper';
import Step from '../../../../components/stepper/step/Step';
import { OrderPhase } from '../../../../models';
import OrderDetailsStepperContext from '../../../../contexts/stepper/OrderDetailsStepperContext';
import { useOrderFormContext } from '../../../../hooks';

import DeliveryDateStep from '../../../../components/order-steps/details/delivery-date-step/DeliveryDateStep';
import PersonalDataStep from '../../../../components/order-steps/details/personal-data-step/PersonalDataStep';
import DeliveryAddressesStep from '../../../../components/order-steps/details/delivery-addresses-step/DeliveryAddressesStep';
import ReviewOrderStep from '../../../../components/order-steps/details/review-order-step/ReviewOrderStep';
import SummaryStep from '../../../../components/order-steps/details/summary-step/SummaryStep';

import PriceInformationStep from '../../../../components/order-steps/price-notifications/price-information-step/PriceInformationStep';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 *
 * This step follows the product selection step and sets up the correct path
 * based upon the user decision to order a product or setup a price notification.
 *
 * @returns the JSX to be used in other components.
 */
export default function ChosenPathPath() {
  const { t } = useTranslation();

  const { path } = useOrderFormContext();

  const detailsIndicatorsDescription = useMemo(
    () => [
      t('Stepper.Details.DeliveryDateIndicator'),
      t('Stepper.Details.PersonalDataIndicator'),
      t('Stepper.Details.DeliveryAddressIndicator'),
      t('Stepper.Details.OfferIndicator'),
      t('Stepper.ReadyIndicator'),
    ],
    [t],
  );

  if (path === OrderPhase.details) {
    return (
      <Step>
        <Stepper
          context={OrderDetailsStepperContext}
          phase={OrderPhase.details}
          variant="timeline"
          indicatorsDescription={detailsIndicatorsDescription}
        >
          <DeliveryDateStep />
          <PersonalDataStep />
          <DeliveryAddressesStep />
          <ReviewOrderStep />
          <SummaryStep />
        </Stepper>
      </Step>
    );
  }

  if (path === OrderPhase.priceNotifications) {
    return <PriceInformationStep />;
  }

  return <span>Unexpected stepper state</span>;
}
