import styled from 'styled-components';

export const ResponsiveTextArea = styled.textarea.attrs(() => ({
  className: 'form-control',
}))<{}>`
  width: 100%;

  @media (min-width: 576px) {
    width: 500px;
  }
`;
