import { Flex } from '../../../../../styled-components';
import { useTranslation } from 'react-i18next';
import { PriceInformationFormValues } from '../PriceInformationStep';
import styled from 'styled-components';
import { Interval } from '../../../../../contexts';

/**
 * The type for the "values" Component prop.
 * This higher order type is made in order not to tie the Component down to the
 * Formik implementation of the "values" object.
 */
type RegularIntervalNotificationFormValues = {
  [key in keyof Pick<PriceInformationFormValues, 'priceSubscription'>]: number | string | undefined;
};

/**
 * The type for the "errors" Component prop.
 * This higher order type is made in order not to tie the Component down to the
 * Formik implementation of the "errors" object.
 */
type RegularIntervalNotificationFormErrors = {
  [key in keyof Pick<PriceInformationFormValues, 'priceSubscription'>]: string | undefined;
};

/**
 * Container for the fields.
 * Useful for the responsiveness of the Component.
 */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: 576px) {
    flex-direction: row;
  }
`;

/**
 * The label + input + tag.
 */
const Field = styled(Flex)`
  margin-right: 0;

  @media (min-width: 576px) {
    margin-right: 1.5rem;
  }
`;

/**
 * The PriceAlarm props.
 */
interface RegularIntervalNotificationProps {
  /**
   * The callback to pass to the inputs as provided by Formik.
   */
  handleChange: {
    /** Classic React change handler, keyed by input name */
    // eslint-disable-next-line
    (e: React.ChangeEvent<any>): void;
    /** Preact-like linkState. Will return a handleChange function.  */
    // eslint-disable-next-line
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void // eslint-disable-next-line
      : (e: string | React.ChangeEvent<any>) => void;
  };

  /**
   * The values for the inputs.
   */
  values: RegularIntervalNotificationFormValues;

  /**
   * The possible errors for the form values.
   */
  errors: RegularIntervalNotificationFormErrors;
}

export default function PriceSubscription({ values, errors, handleChange }: RegularIntervalNotificationProps) {
  const { t } = useTranslation();

  return (
    <Container>
      <Field direction="column">
        <label htmlFor="priceSubscriptionId" className="form-label">
          <span>{t('PriceInformation.Configurators.PriceSubscription.Interval')}</span>
        </label>
        <Flex direction="column" className="position-relative">
          <select
            id="priceSubscriptionId"
            className={`form-select ${!!errors.priceSubscription && 'is-invalid'}`}
            name="priceSubscription"
            value={values.priceSubscription}
            onChange={handleChange}
          >
            <option value={Interval.OneDay}>{t('PriceInformation.Configurators.OneDay')}</option>
            <option value={Interval.ThreeDays}>{t('PriceInformation.Configurators.ThreeDays')}</option>
            <option value={Interval.SevenDays}>{t('PriceInformation.Configurators.SevenDays')}</option>
            <option value={Interval.TwoWeeks}>{t('PriceInformation.Configurators.TwoWeeks')}</option>
            <option value={Interval.FourWeeks}>{t('PriceInformation.Configurators.FourWeeks')}</option>
            <option value={Interval.EightWeeks}>{t('PriceInformation.Configurators.EightWeeks')}</option>
          </select>

          <div className="invalid-tooltip">{errors.priceSubscription}</div>
        </Flex>
      </Field>
    </Container>
  );
}
