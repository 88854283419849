import { useApplicationStatus, useOrderFormContext, useOrderService } from '../../../../hooks';
import Step from '../../../stepper/step/Step';
import { useTranslation } from 'react-i18next';
import withErrorHandling from '../../../common/hoc/with-error-handling/withErrorHandling';
import { useCallback, useEffect, useState } from 'react';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import { ErrorViewModel, IApplicationStatus } from '../../../../models';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { Flex } from '../../../../styled-components';

function SummaryStep() {
  const { t } = useTranslation();
  const language = useCurrentLanguage();

  const { errors, setErrors } = useErrorHandling();

  const orderService = useOrderService();

  const { email, orderId, selectedPromotion } = useOrderFormContext();

  const { deleteApplicationStatus } = useApplicationStatus();

  const [pdfUri, setPdfUri] = useState<string>();
  const [downloadingPdf, setDownloadingPdf] = useState<boolean>(false);

  const getOrderPdf = useCallback(async () => {
    setDownloadingPdf(true);

    try {
      const pdfUri = await orderService.getOrderPdfById(orderId!, language);

      setPdfUri(pdfUri);
    } catch (error) {
      setErrors([...errors, error as ErrorViewModel]);
    } finally {
      setDownloadingPdf(false);
    }
  }, [setDownloadingPdf, orderService, orderId, language, setPdfUri, setErrors, errors]);

  useEffect(() => {
    deleteApplicationStatus<keyof Omit<IApplicationStatus, 'email' | 'orderId'>>([
      'acceptedOrderConditions',
      'acceptedPersonalDataConditions',
      'company',
      'deliveryAddresses',
      'deliveryDates',
      'desiredPrice',
      'desiredPriceExpiration',
      'hoseLength',
      'invoiceAddressNumber',
      'invoiceAddressPostalCode',
      'invoiceAddressLocation',
      'invoiceAddressStreet',
      'name',
      'path',
      'postalCode',
      'priceAlarm',
      'priceAlarmExpiration',
      'priceDetails',
      'priceSubscription',
      'quantity',
      'selectedQuantity',
      'remarks',
      'selectedProduct',
      'shop',
      'smallVehicle',
      'stepper',
      'storageRoomCleaning',
      'surname',
      'telephone',
      'title',
      'unloadingPlaces',
      'selectedUnloadingPlaces',
      'useDesiredPrice',
      'useNewsletter',
      'usePriceAlarm',
      'usePriceSubscription',
      'unloadingPlaceItems',
    ]);
  }, []);

  return (
    <Step>
      <div>
        <div className="d-flex flex-column align-items-center" style={{ marginTop: '2.5rem' }}>
          <span className="d-inline-block fw-bold mb-3">{t('SummaryStep.Thanks')}</span>
          <span>{t('SummaryStep.ConfirmationMailSent')}</span>
          <span className="d-inline-block mb-3">
            ({t('SummaryStep.EMail')}: {email})
          </span>
          <button
            type="button"
            className="btn btn-primary mb-3"
            disabled={downloadingPdf}
            onClick={() => getOrderPdf()}
          >
            {downloadingPdf ? (
              <div className="spinner-border spinner-border-sm text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              t('SummaryStep.OrderPrint')
            )}
          </button>
          {pdfUri && (
            <span>
              {t('SummaryStep.ClickOn')}&nbsp;
              <a href={pdfUri} target="_blank" rel="noopener noreferrer">
                {t('SummaryStep.ThisLink')}
              </a>
              {t('SummaryStep.ToView')}
            </span>
          )}
        </div>
        {selectedPromotion &&
          selectedPromotion.gameficationIFrameLinkUrl &&
          selectedPromotion.gameficationIFrameLinkUrl !== '' && (
            <div className="d-flex flex-column align-items-center">
              <div className="ratio ratio-1x1">
                <iframe
                  src={
                    language === 'fr'
                      ? selectedPromotion.gameficationIFrameLinkUrlFR
                      : selectedPromotion.gameficationIFrameLinkUrl
                  }
                  title={
                    language === 'fr'
                      ? selectedPromotion.gameficationLinkTitleFR
                      : selectedPromotion.gameficationLinkTitle
                  }
                />
              </div>
            </div>
          )}
        {selectedPromotion &&
          selectedPromotion.gameficationLinkUrl &&
          selectedPromotion.gameficationLinkUrl !== '' &&
          selectedPromotion.gameficationIFrameLinkUrl === '' && (
            <div className="d-flex flex-column align-items-center" style={{ marginTop: '2.5rem' }}>
              <a
                href={
                  language === 'fr' ? selectedPromotion.gameficationLinkUrlFR : selectedPromotion.gameficationLinkUrl
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {language === 'fr' ? selectedPromotion.gameficationLinkUrlFR : selectedPromotion.gameficationLinkTitle}
              </a>
            </div>
          )}
      </div>
    </Step>
  );
}

export default withErrorHandling(SummaryStep);
