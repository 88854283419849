import {
  useApplicationStatus,
  useOrderFormContext,
  useProductType,
  useSettingsService,
  useStepper,
} from '../../../../hooks';
import Step from '../../../stepper/step/Step';
import OrderDetailsStepperContext from '../../../../contexts/stepper/OrderDetailsStepperContext';
import { Flex } from '../../../../styled-components';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Title } from '../../../../contexts';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import withErrorHandling from '../../../common/hoc/with-error-handling/withErrorHandling';
import StepHeader from '../../../common/step-header/StepHeader';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';

/**
 * Styles for the form layout.
 */
const Layout = styled(Flex)`
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: ${({ direction }) => direction};
  }
`;

/**
 * Styles for the text area form column.
 */
const TextAreaLayout = styled.div.attrs((attrs) => ({
  ...attrs,
}))<{}>`
  flex: 1;

  @media (min-width: 992px) {
    margin-left: 1.5rem;
  }
`;

/**
 * Styles for the left input section of the page with billing and personal data.
 */
const PersonalDataFormSection = styled.div.attrs((attrs) => ({
  ...attrs,
}))<{}>`
  flex: 1;
  margin-right: 0;

  @media (min-width: 992px) {
    margin-right: 1.5rem;
  }
`;

/**
 * This interface describes the values that are input in this form.
 */
interface PersonalDataFormValues {
  title?: Title;
  name?: string;
  surname?: string;
  company?: string;
  invoiceAddressStreet?: string;
  invoiceAddressNumber?: string;
  invoiceAddressPostalCode?: string;
  invoiceAddressLocation?: string;
  email?: string;
  telephone?: string;
  acceptedPersonalDataConditions?: boolean;
}

function PersonalDataStep() {
  const { t } = useTranslation();

  const { setApplicationStatus } = useApplicationStatus();

  const {
    postalCode,
    remarks,
    setRemarks,

    title,
    setTitle,
    name,
    setName,
    surname,
    setSurname,
    company,
    setCompany,
    invoiceAddressStreet,
    setInvoiceAddressStreet,
    invoiceAddressNumber,
    setInvoiceAddressNumber,
    invoiceAddressPostalCode,
    setInvoiceAddressPostalCode,
    invoiceAddressLocation,
    setInvoiceAddressLocation: setInvoiceAddressResidence,
    email,
    setEmail,
    telephone,
    setTelephone,
    acceptedPersonalDataConditions,
    setAcceptedPersonalDataConditions,
    isTimeoutReached,
  } = useOrderFormContext();

  const { prevStep, nextStep } = useStepper(OrderDetailsStepperContext)!;

  const settingsService = useSettingsService();
  const language = useCurrentLanguage();
  const productTypeString = useProductType();

  /**
   * Callback that performs validation and sets the input form data
   * in the application status.
   * If everything is validated and no errors arise, it goes onto the next step.
   */
  const submitAndNextStep = useCallback(
    (values: PersonalDataFormValues) => {
      const newValues = {
        ...values,
        remarks: remarks,
      };

      settingsService.getSystemInfo(productTypeString, language, t).then((systemInfo) => {
        if (!systemInfo.isSystemOnline) {
          prevStep();

          return;
        }

        setTitle(values.title);
        setName(values.name!);
        setSurname(values.surname!);
        setCompany(values.company);
        setInvoiceAddressStreet(values.invoiceAddressStreet!);
        setInvoiceAddressNumber(values.invoiceAddressNumber!);
        setInvoiceAddressPostalCode(values.invoiceAddressPostalCode!);
        setInvoiceAddressResidence(values.invoiceAddressLocation!);
        setEmail(values.email!);
        setTelephone(values.telephone!);
        setAcceptedPersonalDataConditions(values.acceptedPersonalDataConditions!);

        setApplicationStatus(newValues);

        nextStep();
      });
    },
    [
      remarks,

      setTitle,
      setName,
      setSurname,
      setCompany,
      setInvoiceAddressStreet,
      setInvoiceAddressNumber,
      setInvoiceAddressPostalCode,
      setInvoiceAddressResidence,
      setEmail,
      setTelephone,
      setAcceptedPersonalDataConditions,

      setApplicationStatus,

      nextStep,
    ],
  );

  const initialValues = useMemo<PersonalDataFormValues>(
    () => ({
      title: title,
      name: name,
      surname: surname,
      company: company,
      invoiceAddressStreet: invoiceAddressStreet,
      invoiceAddressNumber: invoiceAddressNumber,
      invoiceAddressPostalCode: invoiceAddressPostalCode || postalCode.npa,
      invoiceAddressLocation: invoiceAddressLocation || postalCode.name,
      email: email,
      telephone: telephone,
      acceptedPersonalDataConditions: acceptedPersonalDataConditions,
    }),
    [
      title,
      name,
      surname,
      company,
      invoiceAddressStreet,
      invoiceAddressNumber,
      invoiceAddressPostalCode,
      postalCode,
      invoiceAddressLocation,
      email,
      telephone,
      acceptedPersonalDataConditions,
    ],
  );

  const personalDataValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        title: Yup.number().required(t('PersonalData.ValidationErrors.Title')),
        name: Yup.string().required(t('PersonalData.ValidationErrors.Name')),
        surname: Yup.string().required(t('PersonalData.ValidationErrors.Name')),
        company: Yup.string(),
        invoiceAddressStreet: Yup.string().required(t('PersonalData.ValidationErrors.Address')),
        invoiceAddressPostalCode: Yup.string()
          .matches(/^[0-9]+$/, t('PostalCodeTypeErrorMessage'))
          .min(4, t('PostalCodeTooShortErrorMessage'))
          .max(4, t('PostalCodeTooLongErrorMessage'))
          .required(t('PersonalData.ValidationErrors.PostalCode')),
        invoiceAddressLocation: Yup.string().required(t('PersonalData.ValidationErrors.Residence')),
        email: Yup.string()
          .matches(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            t('PersonalData.ValidationErrors.InvalidEmail'),
          )
          .required(t('PersonalData.ValidationErrors.Email')),
        telephone: Yup.string().required(t('PersonalData.ValidationErrors.Phone')),
        acceptedPersonalDataConditions: Yup.boolean()
          .isTrue(t('PersonalData.ValidationErrors.AcceptConditions'))
          .required(),
      }),
    [t],
  );

  useEffect(() => {
    async function init() {
      const systemInfo = await settingsService.getSystemInfo(productTypeString, language, t);

      if (!systemInfo || !systemInfo.isSystemOnline) {
        prevStep();
      }

      if (isTimeoutReached()) {
        prevStep();

        return;
      }
    }

    init();
  }, []);

  return (
    <Step>
      <Flex direction="column" className="justify-content-between align-items-start">
        <StepHeader />

        <Formik
          onSubmit={submitAndNextStep}
          initialValues={initialValues}
          validationSchema={personalDataValidationSchema}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => {
            return (
              <Form noValidate onSubmit={handleSubmit} className="align-self-stretch">
                <Layout direction="row" style={{ justifyContent: 'space-between' }}>
                  <PersonalDataFormSection className="d-flex flex-column">
                    <div className="mb-3">
                      {touched.title && errors.title && <span className="d-block text-danger">{errors.title}</span>}
                      <label className="mb-1">{t('PersonalData.Title')}</label>

                      <div>
                        <Form.Check
                          name="title"
                          inline
                          type="radio"
                          id={`id-${t('PersonalData.Miss')}`}
                          label={t('PersonalData.Miss')}
                          value={Title.Miss}
                          checked={Number(values.title) === Title.Miss}
                          onChange={handleChange}
                        />
                        <Form.Check
                          name="title"
                          inline
                          type="radio"
                          id={`id-${t('PersonalData.Mister')}`}
                          label={t('PersonalData.Mister')}
                          value={Title.Mister}
                          checked={Number(values.title) === Title.Mister}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="d-flex mb-3 align-items-end">
                      <div className="me-3" style={{ flex: 1 }}>
                        {(touched.name || touched.surname) && (errors.name || errors.surname) && (
                          <span className="d-block text-danger">{errors.name || errors.surname}</span>
                        )}
                        <label htmlFor="nameId" className="mb-2">
                          {t('PersonalData.Name')}
                        </label>
                        <input
                          id="nameId"
                          name="name"
                          className="form-control"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="ms-3" style={{ flex: 1 }}>
                        <label htmlFor="surnameId" className="mb-2">
                          {t('PersonalData.Surname')}
                        </label>
                        <input
                          id="surnameId"
                          name="surname"
                          className="form-control"
                          value={values.surname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="companyId" className="mb-2">
                        {t('PersonalData.Company')}
                      </label>
                      <input
                        id="companyId"
                        name="company"
                        className="form-control"
                        value={values.company}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="d-flex mb-3 align-items-end">
                      <div className="me-3" style={{ flex: 0.7 }}>
                        {(touched.invoiceAddressStreet || touched.invoiceAddressNumber) &&
                          (errors.invoiceAddressStreet || errors.invoiceAddressNumber) && (
                            <span className="d-block text-danger">
                              {errors.invoiceAddressStreet || errors.invoiceAddressNumber}
                            </span>
                          )}
                        <label htmlFor="invoiceAddressStreetId" className="mb-2">
                          {t('PersonalData.Street')}
                        </label>
                        <input
                          id="invoiceAddressStreetId"
                          name="invoiceAddressStreet"
                          className="form-control"
                          value={values.invoiceAddressStreet}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="ms-3" style={{ flex: 0.3 }}>
                        <label htmlFor="invoiceAddressNumberId" className="mb-2">
                          {t('PersonalData.CivicNumber')}
                        </label>
                        <input
                          id="invoiceAddressNumberId"
                          name="invoiceAddressNumber"
                          className="form-control"
                          value={values.invoiceAddressNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    <div className="d-flex mb-3 align-items-end">
                      <div className="me-3" style={{ flex: 0.3 }}>
                        {touched.invoiceAddressPostalCode && errors.invoiceAddressPostalCode && (
                          <span className="d-block text-danger">{errors.invoiceAddressPostalCode}</span>
                        )}
                        <label htmlFor="invoiceAddressPostalCodeId" className="mb-2">
                          {t('PersonalData.PostalCode')}
                        </label>
                        <input
                          id="invoiceAddressPostalCodeId"
                          name="invoiceAddressPostalCode"
                          className="form-control"
                          value={values.invoiceAddressPostalCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="ms-3" style={{ flex: 0.7 }}>
                        {touched.invoiceAddressLocation && errors.invoiceAddressLocation && (
                          <span className="d-block text-danger">{errors.invoiceAddressLocation}</span>
                        )}
                        <label htmlFor="invoiceAddressResidenceId" className="mb-2">
                          {t('PersonalData.Residence')}
                        </label>
                        <input
                          id="invoiceAddressResidenceId"
                          name="invoiceAddressLocation"
                          className="form-control"
                          value={values.invoiceAddressLocation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      {touched.email && errors.email && <span className="d-block text-danger">{errors.email}</span>}
                      <label htmlFor="emailId" className="mb-2">
                        {t('PersonalData.Email')}
                      </label>
                      <input
                        id="emailId"
                        name="email"
                        className="form-control"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>

                    <div className="mb-3">
                      {touched.telephone && errors.telephone && (
                        <span className="d-block text-danger">{errors.telephone}</span>
                      )}
                      <label htmlFor="telephoneId" className="mb-2">
                        {t('PersonalData.Phone')}
                      </label>
                      <input
                        id="telephoneId"
                        name="telephone"
                        className="form-control"
                        value={values.telephone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>

                    <div>
                      {errors.acceptedPersonalDataConditions && (
                        <span className="mt-3 mb-1 text-danger fw-bold">{errors.acceptedPersonalDataConditions}</span>
                      )}
                      <Form.Check
                        className={`${errors.acceptedPersonalDataConditions ? '' : 'mt-3'} text-danger`}
                        type="checkbox"
                        id={`id-${t('PersonalData.AcceptConditions')}`}
                        label={t('PersonalData.AcceptConditions')}
                        name="acceptedPersonalDataConditions"
                        value={(!values.acceptedPersonalDataConditions).toString()}
                        checked={values.acceptedPersonalDataConditions}
                        onChange={handleChange}
                      />
                    </div>
                  </PersonalDataFormSection>

                  <TextAreaLayout>
                    <label className="form-label">{t('DeliveryDate.Remarks')}</label>
                    <textarea
                      rows={3}
                      className="form-control"
                      style={{ width: '100%' }}
                      value={remarks}
                      onChange={(e) => setRemarks(e.currentTarget.value)}
                    />
                  </TextAreaLayout>
                </Layout>

                <div className="d-flex mt-4">
                  <button className="btn btn-secondary me-1" type="button" onClick={() => prevStep()}>
                    <span>{'< ' + t('DeliveryDate.Back')}</span>
                  </button>
                  <button className="btn btn-primary ms-1" type="submit">
                    <span>{t('DeliveryDate.Next') + ' >'}</span>
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Flex>
    </Step>
  );
}

export default withErrorHandling(PersonalDataStep);
