import { useTranslation } from 'react-i18next';
import { WoodProductName } from '../../../assets/constants/Constants';
import { useOrderFormContext, useProductType } from '../../../hooks';

export default function StepHeader() {
  const { t } = useTranslation();

  const productType = useProductType();

  const { postalCode, selectedQuantity, selectedUnloadingPlaces } = useOrderFormContext();

  return (
    <em className="mt-3 mb-4">
      {t('Steps.PostalCode')}: {postalCode?.npa}, {t('Quantity')}: {selectedQuantity}{' '}
      {productType === WoodProductName ? 'Kg' : t('Liters')}, {t('UnloadingPlaces')}: {selectedUnloadingPlaces}
    </em>
  );
}
