import { useContext, useMemo } from 'react';
import AppConfigurationContext from '../../contexts/application/AppConfigurationContext';
import TextResourceService, { ITextResourceService } from '../../data/textresource-service/TextResourceService';

/**
 *
 * This hook gets an instance of the TextResourceService class
 * to be used in a Component.
 *
 * @returns an ITextResourceService interface implemented by
 * an instance of the TextResourceService class.
 */
export function useTextResourceService(): ITextResourceService {
  const appConfig = useContext(AppConfigurationContext)!;

  const service = useMemo(() => new TextResourceService(appConfig), []);

  return service;
}
