/**
 *
 * This function rounds the price to N decimals.
 *
 * @param price - The price to round.
 *
 * @returns the rounded price.
 */
export default function roundPrice(price: number, decimalsNumber = 2) {
  const cutoff = Math.pow(10, decimalsNumber);

  return (Math.round((price + Number.EPSILON) * cutoff) / cutoff).toFixed(2);
}
