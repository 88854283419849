import { useMemo } from 'react';
import { WoodProductName, FuelProductName, DieselProductName } from '../assets/constants/Constants';

type ProductTypeString = typeof WoodProductName | typeof FuelProductName | typeof DieselProductName;

/**
 *
 * This hook returns the product type based on the current URL.
 *
 * @returns the product type based on the current URL.
 */
export function useProductType(): ProductTypeString {
  const productType = useMemo(() => window.location.pathname.split('/').slice(-1)[0], [window.location.pathname]);
  const a =
    productType === 'holz-pellets' ? WoodProductName : productType === 'diesel' ? DieselProductName : FuelProductName;

  return a;
}
