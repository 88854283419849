import React from 'react';
import { Form } from 'react-bootstrap';
import { Flex } from '../../../../../styled-components';

interface ConfiguratorProps {
  title: string;
  subtitle: string | React.ReactNode;

  showConfigurator: boolean;
  setShowConfigurator: (e: React.ChangeEvent<HTMLInputElement>) => void;

  component: React.ReactNode;
}

export default function Configurator({
  title,
  subtitle,
  showConfigurator,
  setShowConfigurator,
  component,
}: ConfiguratorProps) {
  return (
    <Flex direction="column" className="mb-4">
      <Form.Check
        className="mb-1"
        type="checkbox"
        id={`id-${title}`}
        label={title}
        value={(!showConfigurator).toString()}
        checked={showConfigurator}
        onChange={(e) => setShowConfigurator(e)}
      />
      <p className="mb-4">{subtitle}</p>

      {showConfigurator && component}
    </Flex>
  );
}
