import Step from '../../../stepper/step/Step';
import OrderForm from '../../../order-form/OrderForm';
import { ProductTypes } from '../../../../assets/constants/Constants';

/**
 *
 * This interface describes all the possible props that can be passed
 * to the OrderFormStep Component.
 *
 */
interface OrderFormStepProps {
  type: ProductTypes;
}

export default function OrderFormStep({ type }: OrderFormStepProps) {
  return (
    <Step form>
      <OrderForm type={type} />
    </Step>
  );
}
