const canUseStorage = () => {
  try {
    if (sessionStorage) {
      return true;
    }
  } catch (error) {}

  console.warn('Session storage is not available');
  return false;
};

const get = <T>(key: string): T | null => {
  if (canUseStorage()) {
    const result = sessionStorage.getItem(key);

    if (!!result) {
      return JSON.parse(result) as T;
    }
  }

  return null;
};

const set = <T>(key: string, value: T) => {
  if (canUseStorage()) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

const del = (key: string) => {
  if (canUseStorage()) {
    sessionStorage.removeItem(key);
  }
};

export const storage = { get, set, del };
