import axios, { AxiosInstance } from 'axios';
import { IAppConfiguration } from '../models';

/**
 * The base class for all the services that call the remote API. It provides the base configuration
 * for the Axios package and the default methods for all the verbs.
 */
export default class BaseService {
  axios: AxiosInstance;

  constructor(config: IAppConfiguration, baseUrl: string) {
    this.axios = axios.create({
      baseURL: config.api.baseUrl + baseUrl,
    });

    this.axios.defaults.headers.get['Cache-Control'] = 'no-cache';
    this.axios.defaults.headers.get['Pragma'] = 'no-cache';
    this.axios.defaults.headers.post['Content-Type'] = 'application/json';
    this.axios.defaults.headers.put['Content-Type'] = 'application/json';
    this.axios.defaults.headers.patch['Content-Type'] = 'application/json';
    this.axios.defaults.params = {};
    this.axios.defaults.params['code'] = config.api.authCode;

    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        return Promise.reject({
          title: error.message,
          statusCode: error.respose?.data?.statusCode,
          value: error.response?.data,
        });
      },
    );
  }

  /*
   * Get request are cached by default to allow multiple components
   * to request the same data and return the same inflight promise.
   * Therefore, a request will not be fired twice.
   *
   * @param url the url to get
   */
  async get<TReturn>(url: string, params?: unknown): Promise<TReturn> {
    try {
      return (
        await this.axios.get<TReturn>(url, {
          params: params,
        })
      ).data;
    } catch (error) {
      console.error(error);

      throw error;
    }
  }

  /**
   * Posts the data to the API.
   * @param {*} url The URL address of the POST request.
   * @param {*} data The POST data.
   * @returns a Promise object.
   */
  async post<TReturn>(url: string, data: object): Promise<TReturn> {
    try {
      return (await this.axios.post<TReturn>(url, data)).data;
    } catch (error) {
      console.error(error);

      throw error;
    }
  }

  /**
   *
   * Delete method for API calls.
   *
   * @param url the url to call.
   */
  async delete<TReturn>(url: string, params?: unknown): Promise<TReturn> {
    try {
      return (
        await this.axios.delete<TReturn>(url, {
          params: params,
        })
      ).data;
    } catch (error) {
      console.error(error);

      throw error;
    }
  }
}
