import { ComponentType, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { ErrorViewModel } from '../../../../models';
import { ErrorHandlingProvider } from '../../../..//contexts/error-handling/ErrorHandlingContext';

/**
 *
 * This HOC takes care of wrapping another component in an error handling
 * environment, so that by using the useErrorHandling hook, it's possible to
 * display the custom error biscuits/toasts in the UI.
 *
 * @param WrappedComponent - the React Component to be wrapped in the error handling.
 *
 * @returns the Component wrapped in error handling that can be used anywhere.
 */
export default function withErrorHandling<P>(WrappedComponent: ComponentType<P>): ComponentType<P> {
  return (props: P) => {
    const [errors, setErrors] = useState<ErrorViewModel[]>([]);

    return (
      <ErrorHandlingProvider value={{ errors: errors, setErrors: setErrors }}>
        <ToastContainer style={{ position: 'absolute', right: 10, bottom: 10, zIndex: 99 }}>
          {errors.map((error, index) => (
            <Toast
              bg="danger"
              style={{ backgroundClip: 'initial', boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.15)' }}
              key={index}
              onClose={() => {
                const newArray = [...errors];
                newArray.splice(index, 1);
                setErrors(newArray);
              }}
            >
              <Toast.Header style={{ backgroundClip: 'initial' }}>
                <strong>An error occurred while performing the action</strong>
              </Toast.Header>
              {error.value != null && error.value != undefined && error.value.description != null ? (
                <Toast.Body>
                  {/* {error.title != null && error.title != '' ? <div>{error.title}</div> : null} */}
                  <div>{error.value.description}</div>
                </Toast.Body>
              ) : null}
            </Toast>
          ))}
        </ToastContainer>
        <WrappedComponent {...props}></WrappedComponent>
      </ErrorHandlingProvider>
    );
  };
}
