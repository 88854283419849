import './i18n';

import OrderFormContextProvider from './components/order-form/OrderFormContextProvider';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import OrderFormStep from './components/order-steps/landing/form-step/OrderFormStep';
import ProductSelectionStep from './components/order-steps/landing/product-selection-step/ProductSelectionStep';
import ChosenPathStep from './components/order-steps/landing/chosen-path-step/ChosenPathStep';

import Stepper from './components/stepper/Stepper';
import Unsubscribe from './components/unsubscribe-page/Unsubscribe';
import OrderStepperContext from './contexts/stepper/OrderStepperContext';
import { IAppConfiguration, OrderPhase, PostalCode } from './models';
import { AppConfigurationProvider } from './contexts/application/AppConfigurationContext';
import { useOrderQueryParams, usePostalCodeService } from './hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 *
 * This is the main entry point of our React application.
 *
 * @returns the main entry point of the application.
 */
export default function App(config: IAppConfiguration) {
  const orderQueryParams = useOrderQueryParams();

  const areQueryParamsComplete = useMemo(
    () => !!orderQueryParams.postalCode && !!orderQueryParams.quantity && !!orderQueryParams.unloadingPlaces,
    [orderQueryParams],
  );

  const postalCodeService = usePostalCodeService(config);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [postalCodesWithNpa, setPostalCodesWithNpa] = useState<PostalCode[]>();

  const isNpaUnique = useMemo(
    () => (postalCodesWithNpa ? postalCodesWithNpa.length <= 1 : false),
    [postalCodesWithNpa],
  );

  const getAvailablePostalCodesWithNpa = useCallback(
    async (npa: string) => {
      try {
        return await postalCodeService.getPostalCodesWithNpa(npa);
      } catch (error) {
        return [];
      }
    },
    [postalCodeService, setPostalCodesWithNpa],
  );

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);

      if (orderQueryParams.postalCode) {
        const apcWithNpa = await getAvailablePostalCodesWithNpa(orderQueryParams.postalCode);

        setPostalCodesWithNpa(apcWithNpa);
      } else {
        setPostalCodesWithNpa([]);
      }

      setIsLoading(false);
    }

    loadData();
  }, []);

  if (isLoading) {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <AppConfigurationProvider value={config}>
      <OrderFormContextProvider>
        {({ postalCode, setPostalCode }) => {
          let isPostalCodeComplete = !!postalCode.npa && !!postalCode.name;
          const doesPostalCodeExist = postalCodesWithNpa!.length >= 1;

          if (doesPostalCodeExist && isNpaUnique && !isPostalCodeComplete) {
            const completePostalCode = postalCodesWithNpa!.find(
              (pc) => pc.npa.toString() === orderQueryParams.postalCode,
            );

            if (completePostalCode) {
              setPostalCode({
                npa: completePostalCode.npa.toString(),
                name: completePostalCode.name,
              });

              isPostalCodeComplete = true;
            } else {
              console.warn(
                "Unexpectedly couldn't find postal code to choose from. Starting the application from the first step anyways...",
              );
            }
          }

          return (
            <Router>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/de/holz-pellets" />
                </Route>
                <Route path="/*/holz-pellets">
                  <Stepper
                    context={OrderStepperContext}
                    startingStep={areQueryParamsComplete && isPostalCodeComplete ? 1 : 0}
                    phase={OrderPhase.form}
                  >
                    <OrderFormStep type="Wood" />
                    <ProductSelectionStep />
                    <ChosenPathStep />
                  </Stepper>
                </Route>
                <Route path="/*/heizol">
                  <Stepper
                    context={OrderStepperContext}
                    startingStep={areQueryParamsComplete && isPostalCodeComplete ? 1 : 0}
                    phase={OrderPhase.form}
                  >
                    <OrderFormStep type="Fuel" />
                    <ProductSelectionStep />
                    <ChosenPathStep />
                  </Stepper>
                </Route>
                <Route path="/*/diesel">
                  <Stepper
                    context={OrderStepperContext}
                    startingStep={areQueryParamsComplete && isPostalCodeComplete ? 1 : 0}
                    phase={OrderPhase.form}
                  >
                    <OrderFormStep type="Diesel" />
                    <ProductSelectionStep />
                    <ChosenPathStep />
                  </Stepper>
                </Route>
                <Route path="/*/unsubscribe/:internalGuid">
                  <Unsubscribe />
                </Route>
              </Switch>
            </Router>
          );
        }}
      </OrderFormContextProvider>
    </AppConfigurationProvider>
  );
}
