import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAlertService } from '../../hooks';

/**
 * Interface to bind against (cast to) for the useParams hook from react-router.
 */
interface UnsubscribeParams {
  internalGuid: string;
}

/**
 *
 * This Component represents the landing "page" for the link provided
 * in the emails to unsubscribe from a subscription.
 * It asks for confirmation for calling the deletion API and shows a message
 * based on the result.
 *
 * @returns the Component to be used in JSXs.
 */
export default function Unsubscribe() {
  const { internalGuid } = useParams() as UnsubscribeParams;

  const { t } = useTranslation();

  const alertService = useAlertService();

  const [choice, setChoice] = useState<string>('');

  const tryUnsubscribe = useCallback(async () => {
    if (confirm(t('UnsubscribePage.AlertMessage'))) {
      try {
        await alertService.deleteSubscriptionByInternalId(internalGuid);

        setChoice(t('UnsubscribePage.Success'));
      } catch (error) {
        console.error(error);

        setChoice(t('UnsubscribePage.Error'));
      }
    } else {
      setChoice(t('UnsubscribePage.Cancel'));
    }
  }, [t, alertService, internalGuid, setChoice]);

  useEffect(() => {
    tryUnsubscribe();
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <span className="d-inline-block fw-bold m-3">{choice}</span>
    </div>
  );
}
