import { AvailableLanguages } from '../assets/constants/Constants';
import i18next from '../i18n';

/**
 *
 * This hook returns the always updated current language.
 *
 * @returns the current language in string.
 */
export default function useCurrentLanguage(): AvailableLanguages {
  return i18next.language === 'de' ? 'de' : 'fr';
}
