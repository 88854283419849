import { Language } from '../../assets/enums';
import { IAppConfiguration, TextResource } from '../../models';
import BaseService from '../BaseService';

/**
 * This is the interface that the class TextResourceService
 * is going to implement.
 * A hook is then going to be used to retrieve an instance
 * to be used in the components whenever needed.
 */
export interface ITextResourceService {
  getTextResources(lang: Language, prefix: string): Promise<TextResource[]>;
}

/**
 * This is the class that implements the ITextResourceService interface.
 * Make use of the related hook to use it in the Components.
 */
export default class TextResourceService extends BaseService implements ITextResourceService {
  constructor(config: IAppConfiguration) {
    super(config, '/textresources');
  }
  async getTextResources(lang: Language, prefix: string): Promise<TextResource[]> {
    try {
      return await this.get(`/${lang}/${prefix}`);
    } catch (error) {
      console.log('error occurred by loading text resource', error);
    }
    return [];
  }
}
