import React, { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import StepperIndicators from './stepper-indicators/StepperIndicators';
import { StepperContextValues } from '../../contexts';
import { OrderPhase } from '../../models';
import { useApplicationStatus, useProductType } from '../../hooks';

/**
 * The Stepper props.
 */
interface StepperProps {
  /**
   * The steps to display.
   * The nodes have to be wrapped in the Step Component.
   * e.g.: <Step>...</Step>
   */
  children: React.ReactNodeArray;

  /**
   * The step to start from.
   * Indexing starts from 0.
   * Optional: if left out, the stepper begins from the first step.
   */
  startingStep?: number;

  /**
   * The context to wrap the stepper around.
   */
  context: React.Context<StepperContextValues | undefined>;

  /**
   * The phase that this stepper refers to.
   */
  phase: OrderPhase;

  /**
   *
   * The variant of the
   */
  variant?: 'plain' | 'timeline';

  /**
   * If the variant is set to 'timeline', the description for each step.
   */
  indicatorsDescription?: string[];
}

/**
 *
 * This Component acts as the father of the various children steps
 * so that it can initialize the stepper with the appropriate number
 * of possible steps, in order to generate automatically the next or
 * back buttons inside the Step children components.
 *
 * @param children - The steps that this Stepper has to go through.
 * @param variant - The style of this component.
 *
 * @returns the JSX.Element or null to be rendered.
 */
export default function Stepper({
  children,
  startingStep,
  context: Context,
  phase,
  indicatorsDescription,
  variant = 'plain',
}: StepperProps) {
  const [retrievedStatus, setRetrievedStatus] = useState<boolean>(false);

  const { getApplicationStatus, setApplicationStatus } = useApplicationStatus();
  const productTypeFromUrl = useProductType();

  const [step, setStep] = useState<number>(startingStep ?? 0);
  const [stepsCount, setStepsCount] = useState<number>(children.length);
  const stepperFallback = {
    [OrderPhase.form]: 1,
    [OrderPhase.details]: 0,
    [OrderPhase.priceNotifications]: 0,
  };

  const nextStep = useCallback(
    () =>
      setStep((step) => {
        const newStep = step + 1 < stepsCount ? step + 1 : step;

        const { stepper } = getApplicationStatus();

        setApplicationStatus({
          stepper: {
            ...stepper,
            [phase]: newStep,
          },
        });

        return newStep;
      }),
    [stepsCount, getApplicationStatus, setApplicationStatus, phase],
  );

  const prevStep = useCallback(
    () =>
      setStep((step) => {
        const newStep = step - 1 >= 0 ? step - 1 : step;

        const { stepper } = getApplicationStatus();

        setApplicationStatus({
          stepper: {
            ...stepper,
            [phase]: newStep,
          },
        });

        return newStep;
      }),
    [getApplicationStatus, setApplicationStatus, phase],
  );

  const hasProductUrlChanged = useMemo(() => {
    const { stepper } = getApplicationStatus();
    const { selectedProduct } = getApplicationStatus();
    return (
      stepper &&
      selectedProduct?.productType?.productName &&
      selectedProduct?.productType.productName !== productTypeFromUrl
    );
  }, [getApplicationStatus]);

  useEffect(() => {
    const { stepper } = getApplicationStatus();

    if (hasProductUrlChanged) {
      const newStepper = { ...stepperFallback };

      setApplicationStatus({
        stepper: {
          ...newStepper,
        },
      });
      setStep(newStepper[phase]);
    } else {
      stepper && stepper[phase] && setStep(stepper[phase]);
    }

    setRetrievedStatus(true);
  }, []);

  if (!retrievedStatus) {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <Context.Provider
      value={{
        nextStep,
        prevStep,
        setStepsCount,
        step,
        stepsCount,
      }}
    >
      <div>
        {variant === 'timeline' && (
          <StepperIndicators context={Context} currentStep={step} indicatorsDescription={indicatorsDescription ?? []} />
        )}
        {children[step]}
      </div>
    </Context.Provider>
  );
}
