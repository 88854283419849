import { useMemo } from 'react';
import { MainPageQueryParams } from '../models';
import {
  portalQueryParam,
  postalCodeQueryParam,
  quantityQueryParam,
  unloadingPlacesCountQueryParam,
} from '../assets/constants/Constants';

/**
 *
 * This hook returns the query parameters coming from the main Agrola portal.
 *
 * @returns a MainPageQueryParams object with corresponding values.
 */
export function useOrderQueryParams() {
  const mainPageQueryParams = useMemo<MainPageQueryParams>(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return {
      postalCode: params[postalCodeQueryParam],
      quantity: Number(params[quantityQueryParam] ?? 0),
      unloadingPlaces: Number(params[unloadingPlacesCountQueryParam] ?? 0),
      portal: params[portalQueryParam],
    };
  }, []);

  return mainPageQueryParams;
}
