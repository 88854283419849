import { useContext, useMemo } from 'react';
import AppConfigurationContext from '../../contexts/application/AppConfigurationContext';
import AlertService, { IAlertService } from '../../data/alert-service/AlertService';

/**
 *
 * This hook gets an instance of the AlertService class
 * to be used in a Component.
 *
 * @returns an IAlertService interface implemented by
 * an instance of the AlertService class.
 */
export function useAlertService(): IAlertService {
  const appConfig = useContext(AppConfigurationContext)!;

  const alertService = useMemo(() => new AlertService(appConfig), []);

  return alertService;
}
