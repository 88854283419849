export default function numberWithApostrophe(x: number | string): string {
  if (typeof x === 'number') {
    return x
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}
