import { IAppConfiguration } from '../../models';
import { Shop } from '../../models/offers-service/Shop';
import BaseService from '../BaseService';

/**
 * This is the interface that the class ShopService
 * is going to implement.
 * A hook is then going to be used to retrieve an instance
 * to be used in the Components whenever needed.
 */
export interface IShopService {
  getAllSelectableShops(productTypeId: number): Promise<Shop[]>;
}

/**
 * This is the class that implements the IShopService interface.
 * Make use of the related hook to use it in the Components.
 */
export default class ShopService extends BaseService implements IShopService {
  constructor(config: IAppConfiguration) {
    super(config, '/shop');
  }

  async getAllSelectableShops(productTypeId: number): Promise<Shop[]> {
    return await this.get<Shop[]>(`/associated/list/${productTypeId}`);
  }
}
