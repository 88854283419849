import {
  useApplicationStatus,
  useOrderFormContext,
  useOrderService,
  useProductType,
  useSettingsService,
  useStepper,
} from '../../../../hooks';
import Step from '../../../stepper/step/Step';
import OrderDetailsStepperContext from '../../../../contexts/stepper/OrderDetailsStepperContext';
import { Flex } from '../../../../styled-components';
import { useTranslation } from 'react-i18next';
import { AvailableLanguages, WoodProductName, DieselProductName } from '../../../../assets/constants/Constants';
import { Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import withErrorHandling from '../../../common/hoc/with-error-handling/withErrorHandling';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import { Title } from '../../../../contexts';
import { isErrorViewModel, OrderData } from '../../../../models';
import moment, { lang } from 'moment';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import { Language, UnitCode } from '../../../../assets/enums';
import roundPrice from '../../../../utils/roundPrice';
import numberWithApostrophe from '../../../../utils/numberWithApostrophe';
import StepHeader from '../../../common/step-header/StepHeader';

/**
 *
 * This function converts a date to the given current language local.
 *
 * @param date - The date to convert.
 * @param currentLanguage - The current language tag. e.g.: "de", "fr",...
 *
 * @returns the date casted to the correct locale without the date.
 */
function fromDateToLocaleDateString(date: Date, currentLanguage: AvailableLanguages) {
  switch (currentLanguage) {
    case 'de':
      return date.toLocaleDateString(`de-CH`);

    case 'fr':
      return date.toLocaleDateString('fr-CH');

    default:
      return date.toLocaleDateString();
  }
}

/**
 * This interface describes the props for the ReponsiveRow styled component.
 */
interface ResponsiveRowProps {
  borderTop?: boolean;
}

/**
 * Styles for the row layout.
 */
const ResponsiveRow = styled.div<ResponsiveRowProps>`
  display: flex;
  flex-direction: column;

  ${({ borderTop }) => (borderTop ? 'padding-top: 15px; border-top: 1px solid lightgrey;' : '')}

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

/**
 * The styles for box/table wrapper which distances it from the separation line.
 */
const OrderBox = styled.div`
  border-top: 1px solid lightgrey;
  padding: 15px 0 15px 0;
`;

/**
 * The styles for the border around the box/table and the overflow rule.
 */
const OrderDetails = styled.div`
  border: 1px solid black;
  background-color: #f3f3f3;
  padding: 10px;
  overflow-x: auto;
`;

/**
 * The styles for the order details table.
 */
const OrderTable = styled.table`
  border-collapse: collapse;

  td,
  th {
    text-align: left;
  }

  thead {
    tr {
      th:last-child {
        text-align: right;
      }
    }
  }

  td {
    vertical-align: top;
    min-width: 150px;
  }

  th {
    text-decoration: underline;
    font-weight: 700;
    padding-bottom: 0.25em;
  }

  tbody {
    tr {
      td:last-child {
        text-align: right;
      }
    }

    tr:nth-child(1) {
      td {
        padding-bottom: 0.5em;
      }
    }
  }

  tfoot {
    tr {
      td:last-child {
        text-align: right;
      }
    }
  }
`;

/**
 * The form values.
 */
interface ReviewOrderFormValues {
  acceptedOrderConditions: boolean;
  storageRoomCleaning: boolean;
  smallVehicle: boolean;
  hoseLength: boolean;
  selectedPromotionGift?: string;
}

function ReviewOrderStep() {
  const { t } = useTranslation();
  const language = useCurrentLanguage();

  const { errors, setErrors } = useErrorHandling();

  const { setApplicationStatus } = useApplicationStatus();

  const orderService = useOrderService();

  const productType = useProductType();

  const settingsService = useSettingsService();
  const productTypeString = useProductType();

  const { prevStep, nextStep } = useStepper(OrderDetailsStepperContext)!;

  const {
    selectedQuantity,
    selectedUnloadingPlaces,

    shop,
    selectedProduct,
    priceDetails,

    remarks,
    setRemarks,

    deliveryDates,

    title,
    name,
    surname,
    company,
    invoiceAddressStreet,
    invoiceAddressNumber,
    invoiceAddressPostalCode,
    invoiceAddressLocation,
    email,
    telephone,

    deliveryAddresses,

    acceptedOrderConditions,
    storageRoomCleaning,
    smallVehicle,
    hoseLength,

    setOrderId,
    isTimeoutReached,
    selectedPromotion,
  } = useOrderFormContext();

  const [loadingPrices, setLoadingPrices] = useState<boolean>(true);

  const [isDataValid, setIsDataValid] = useState<boolean>(false);

  const [previewOrderData, setPreviewOrderData] = useState<OrderData>();

  const sortedDeliveryDates = useMemo(() => {
    return deliveryDates
      ? [...deliveryDates].sort((a, b) => {
          const first = moment(a);
          const second = moment(b);

          return first.diff(second);
        })
      : [];
  }, [deliveryDates]);

  /**
   * Checks that the sortedDeliveryDates can be used as expected
   * and returns the correct element to visualize in the UI.
   */
  const showDeliveryPeriod = useCallback(() => {
    if (sortedDeliveryDates && sortedDeliveryDates.length > 0 && sortedDeliveryDates.length % 7 === 0) {
      return (
        <span className="mb-3">
          {fromDateToLocaleDateString(sortedDeliveryDates[0], language)} -{' '}
          {fromDateToLocaleDateString(sortedDeliveryDates[sortedDeliveryDates.length - 3], language)}
        </span>
      );
    } else {
      return <span className="mb-3 text-danger fw-bold">{t('ReviewOrder.Errors.DeliveryPeriod')}</span>;
    }
  }, [sortedDeliveryDates, t]);

  const prepareOrderData = async (values: ReviewOrderFormValues) => {
    const orderData: OrderData = {
      clientComment: remarks,
      deliveryAddresses: deliveryAddresses!.map((address) => ({
        houseNumber: address.civicNumber,
        location: address.residence,
        postalCode: address.postalCode,
        street: address.street,
        quantity: address.quantity,
        fillTank: address.fillTank,
      })),
      deliveryWeeks: [...new Set(deliveryDates!.map((date) => moment(date).isoWeek()))],
      email: email,
      invoiceSalutation: t(`PersonalData.${Title[title!]}`),
      invoiceName: name,
      invoiceSurname: surname,
      invoiceCompany: company ?? '',
      invoicePostalCode: invoiceAddressPostalCode,
      invoiceStreet: invoiceAddressStreet,
      invoiceHouseNumber: invoiceAddressNumber,
      invoiceLocation: invoiceAddressLocation,
      language: language === 'de' ? Language.DE : Language.FR,
      paymentType: t('ReviewOrder.PaymentMethod'),
      phone: telephone,
      portalId: 1, // PLACEHOLDER
      productDescription: selectedProduct!['name' + capitalizeFirstLetter(language)],
      productId: selectedProduct!.id,
      quantity: selectedQuantity,
      quantityUnit:
        productType === WoodProductName ? UnitCode.kg : productType === DieselProductName ? UnitCode.lt : UnitCode.lt,
      shopContact: shop!.name,
      shopId: shop!.id,
      unloadingPlacesCount: selectedUnloadingPlaces,
      priceOffer: priceDetails!,
      totalPriceIncludingVAT: priceDetails?.totalPrice,
      storageRoomCleaning: values.storageRoomCleaning,
      smallVehicle: values.smallVehicle,
      hoseLength: values.hoseLength,
      promotionGift: selectedPromotion?.promotionGifts.find(
        (item) =>
          item.descriptionDE === values.selectedPromotionGift || item.descriptionFR === values.selectedPromotionGift,
      ),
    };

    try {
      const preparedOrderData = await orderService.getSummaryPrices(orderData);

      setPreviewOrderData(preparedOrderData);
    } catch (error) {
      if (isErrorViewModel(error)) {
        setErrors([...errors, error]);
      } else {
        const castedError = error as Error;

        setErrors([
          ...errors,
          {
            title: castedError.message,
            statusCode: '',
            value: {
              description: castedError.message,
            },
          },
        ]);
      }
    }
  };

  /**
   * Callback that performs validation and sets the input form data
   * in the application status.
   * If everything is validated and no errors arise, it goes onto the next step.
   */
  const submitAndNextStep = async (values: ReviewOrderFormValues) => {
    if (isTimeoutReached()) {
      prevStep();

      return;
    }

    if (isDataValid) {
      const newValues = {
        ...values,
        remarks: remarks,
      };

      setApplicationStatus(newValues);

      try {
        const systemInformation = await settingsService.getSystemInfo(productTypeString, language, t);
        if (!systemInformation.isSystemOnline) {
          prevStep();

          return;
        }

        if (!previewOrderData) {
          throw new Error('preview order data is undefined');
        }

        const orderId = await orderService.createOrder({
          ...previewOrderData,
          clientComment: remarks,
        });

        setOrderId(orderId);

        setApplicationStatus({
          orderId: orderId,
        });

        nextStep();
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errors, error]);
        } else {
          const castedError = error as Error;

          setErrors([
            ...errors,
            {
              title: castedError.message,
              statusCode: '',
              value: {
                description: castedError.message,
              },
            },
          ]);
        }
      }
    }
  };

  const initialValues = useMemo<ReviewOrderFormValues>(
    () => ({
      acceptedOrderConditions: acceptedOrderConditions ?? false,
      storageRoomCleaning: storageRoomCleaning ?? false,
      smallVehicle: smallVehicle ?? false,
      hoseLength: hoseLength ?? false,
      selectedPromotionGift: undefined,
    }),
    [],
  );

  const reviewOrderValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        acceptedOrderConditions: Yup.boolean().isTrue(t('ReviewOrder.ValidationErrors.AcceptConditions')).required(),
        selectedPromotionGift:
          (selectedPromotion?.promotionGifts?.length ?? 0) > 0
            ? Yup.string().required(t('ReviewOrder.ValidationErrors.PromotionGiftRequired'))
            : Yup.string(),
      }),
    [t],
  );

  /**
   * Callback to verify that the data coming from the Context is consistent.
   */
  const checkDataValidity = useCallback(() => {
    if (deliveryDates === undefined || deliveryDates.length <= 0 || deliveryDates.length % 7 !== 0) {
      setIsDataValid(false);

      return;
    }

    if (isTimeoutReached()) {
      prevStep();

      return;
    }

    // can be expanded with other checks if necessary...

    setIsDataValid(true);
  }, [deliveryDates, setIsDataValid]);

  useEffect(() => {
    async function init() {
      const systemInfo = await settingsService.getSystemInfo(productTypeString, language, t);
      if (!systemInfo || !systemInfo.isSystemOnline) {
        prevStep();
      }
    }

    if (isTimeoutReached()) {
      prevStep();

      return;
    }

    init();
  }, []);

  useEffect(() => {
    async function init() {
      await prepareOrderData(initialValues);
      setLoadingPrices(false);
    }

    init();
  }, []);

  useEffect(() => {
    checkDataValidity();
  }, [checkDataValidity]);

  if (loadingPrices) {
    return (
      <div className="mt-2 d-flex justify-content-start">
        <div className="spinner-border spinner-border" role="status">
          <span className="visually-hidden">{t('Loading')}</span>
        </div>
      </div>
    );
  }

  if (!selectedProduct) {
    return <span>{t('ReviewOrder.Errors.SelectedProductUndefined')}</span>;
  }

  return (
    <Step>
      <Flex direction="column" className="justify-content-between align-items-start">
        <StepHeader />

        <Formik
          onSubmit={submitAndNextStep}
          initialValues={initialValues}
          validationSchema={reviewOrderValidationSchema}
        >
          {({ handleSubmit, handleChange, values, errors, isSubmitting, touched }) => {
            return (
              <Form noValidate onSubmit={handleSubmit} className="align-self-stretch">
                <Flex direction="column">
                  <ResponsiveRow className="mb-4">
                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                      <span className="d-inline-block fw-bold mb-1">{t('ReviewOrder.BillingAddress')}</span>
                      <span>{t(`PersonalData.${Title[title!]}`)}</span>
                      <span>{`${name} ${surname}`}</span>
                      <span>{`${company}`}</span>
                      <span>{`${invoiceAddressStreet} ${invoiceAddressNumber}`}</span>
                      <span>{`${invoiceAddressPostalCode} ${invoiceAddressLocation}`}</span>
                    </div>

                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                      <span className="d-inline-block fw-bold mb-1">{t('ReviewOrder.DeliveryAddress')}</span>
                      {deliveryAddresses?.map((deliveryAddress, index) => (
                        <p key={index} className="mb-2">
                          <span className="d-block">
                            {`${deliveryAddress.street || invoiceAddressStreet} ${
                              deliveryAddress.civicNumber || invoiceAddressNumber
                            }`}
                          </span>
                          <span className="d-block">
                            {`${deliveryAddress.postalCode || invoiceAddressPostalCode} ${
                              deliveryAddress.residence || invoiceAddressLocation
                            } ${deliveryAddress.quantity} ${productType === WoodProductName ? 'kg' : t('Liters')}`}
                          </span>

                          <span className="d-block">
                            {`${t('ReviewOrder.FillTank')}: ${deliveryAddress.fillTank ? t('Yes') : t('No')}`}
                          </span>
                        </p>
                      ))}
                    </div>
                  </ResponsiveRow>

                  <OrderBox>
                    <OrderDetails className="d-flex">
                      <OrderTable style={{ flex: 1 }}>
                        <thead>
                          <tr>
                            <th>{t('ReviewOrder.DetailsTable.Quantity')}</th>
                            <th>{t('ReviewOrder.DetailsTable.Description')}</th>
                            <th>{t('ReviewOrder.DetailsTable.UnitPrice')}</th>
                            <th>{t('ReviewOrder.DetailsTable.TotalPrice')}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <span>
                                {`${selectedQuantity} ${productType === WoodProductName ? 'kg' : t('Liters')}`}
                              </span>
                            </td>
                            <td>
                              <span>{selectedProduct['name' + capitalizeFirstLetter(language)]}</span>
                            </td>
                            <td>
                              <p className="m-0">
                                <span>
                                  {numberWithApostrophe(roundPrice(previewOrderData?.priceWithoutVAT ?? 0))}{' '}
                                  {productType === WoodProductName
                                    ? t('ReviewOrder.DetailsTable.UnitCodeWood')
                                    : productType === DieselProductName
                                    ? t('ReviewOrder.DetailsTable.UnitCodeDiesel')
                                    : t('ReviewOrder.DetailsTable.UnitCodeFuel')}{' '}
                                </span>
                                <small
                                  dangerouslySetInnerHTML={{
                                    __html: `(${
                                      productType === WoodProductName
                                        ? t('ReviewOrder.DetailsTable.ExcludedTaxInfoWood')
                                        : productType === DieselProductName
                                        ? t('ReviewOrder.DetailsTable.ExcludedTaxInfoDiesel')
                                        : t('ReviewOrder.DetailsTable.ExcludedTaxInfoFuel')
                                    })`,
                                  }}
                                />
                              </p>

                              <p className="mb-1" style={{ color: 'gray' }}>
                                <span>
                                  {numberWithApostrophe(roundPrice(previewOrderData?.priceWithVAT ?? 0))}{' '}
                                  {productType === WoodProductName
                                    ? t('ReviewOrder.DetailsTable.UnitCodeWood')
                                    : productType === DieselProductName
                                    ? t('ReviewOrder.DetailsTable.UnitCodeDiesel')
                                    : t('ReviewOrder.DetailsTable.UnitCodeFuel')}{' '}
                                </span>
                                <small
                                  dangerouslySetInnerHTML={{
                                    __html: `(${
                                      productType === WoodProductName
                                        ? t('ReviewOrder.DetailsTable.TaxInfoWood')
                                        : productType === DieselProductName
                                        ? t('ReviewOrder.DetailsTable.TaxInfoDiesel')
                                        : t('ReviewOrder.DetailsTable.TaxInfoFuel')
                                    })`,
                                  }}
                                />
                              </p>
                            </td>
                            <td>
                              {numberWithApostrophe(
                                roundPrice(
                                  (previewOrderData?.totalPriceWithoutVAT ?? 0) -
                                    (previewOrderData?.optionsWithoutVAT ?? 0),
                                ),
                              )}{' '}
                              CHF
                            </td>
                          </tr>

                          {!!previewOrderData?.optionsWithoutVAT && (
                            <tr>
                              <td></td>
                              <td>{t('ReviewOrder.OptionsRestrictions')}</td>
                              <td></td>
                              <td>{previewOrderData.optionsWithoutVAT.toFixed(2)} CHF</td>
                            </tr>
                          )}

                          {!!previewOrderData?.priceOffer.totalDiscount && (
                            <tr>
                              <td></td>
                              <td>{t('ReviewOrder.DetailsTable.Discount')}</td>
                              <td></td>
                              <td>
                                - {numberWithApostrophe(roundPrice(previewOrderData?.priceOffer.totalDiscount ?? 0))}{' '}
                                CHF
                              </td>
                            </tr>
                          )}

                          <tr>
                            <td></td>
                            <td>
                              {roundPrice((previewOrderData?.priceOffer?.vatRate ?? 0) * 100)}
                              {t('ReviewOrder.DetailsTable.VatInformation')}
                            </td>
                            <td></td>
                            <td>{numberWithApostrophe(roundPrice(previewOrderData?.totalVAT ?? 0))} CHF</td>
                          </tr>
                        </tbody>

                        <tfoot style={{ borderTop: '1px solid black' }}>
                          <tr>
                            <td>
                              <span className="fw-bold">{t('ReviewOrder.DetailsTable.TotalSum')}:</span>
                            </td>
                            <td></td>
                            <td>
                              <p className="m-0 fw-bold">
                                <span>
                                  {numberWithApostrophe(roundPrice(previewOrderData?.priceDiscountedWithVAT ?? 0))}{' '}
                                  {productType === WoodProductName
                                    ? t('ReviewOrder.DetailsTable.UnitCodeWood')
                                    : productType === DieselProductName
                                    ? t('ReviewOrder.DetailsTable.UnitCodeDiesel')
                                    : t('ReviewOrder.DetailsTable.UnitCodeFuel')}{' '}
                                </span>
                                <small
                                  className="fw-normal"
                                  dangerouslySetInnerHTML={{
                                    __html: `(${
                                      productType === WoodProductName
                                        ? t('ReviewOrder.DetailsTable.TaxInfoWood')
                                        : productType === DieselProductName
                                        ? t('ReviewOrder.DetailsTable.TaxInfoDiesel')
                                        : t('ReviewOrder.DetailsTable.TaxInfoFuel')
                                    })`,
                                  }}
                                />
                              </p>
                            </td>
                            <td>
                              <span className="fw-bold d-block">
                                {numberWithApostrophe(
                                  roundPrice(previewOrderData?.totalPriceIncludingVATDiscounted ?? 0),
                                )}{' '}
                                CHF
                              </span>
                            </td>
                          </tr>
                        </tfoot>
                      </OrderTable>
                    </OrderDetails>
                  </OrderBox>

                  <ResponsiveRow borderTop className="mb-1">
                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                      <span className="d-inline-block fw-bold mb-1">{t('ReviewOrder.Email')}</span>
                      <span className="mb-3">{email}</span>

                      <span className="d-inline-block fw-bold mb-1">{t('ReviewOrder.Telephone')}</span>
                      <span className="mb-3">{telephone}</span>

                      <span className="d-inline-block fw-bold mb-1">{t('ReviewOrder.DesiredDeliveryWeek')}</span>
                      {showDeliveryPeriod()}

                      <span className="d-inline-block fw-bold mb-1">{t('ReviewOrder.PaymentMethodLabel')}</span>
                      <span className="mb-3">{t('ReviewOrder.PaymentMethod')}</span>

                      <span className="d-inline-block fw-bold mb-1">{t('ReviewOrder.Supplier')}</span>
                      <span className="mb-3">
                        {shop!.name}, {shop!.address}
                      </span>
                    </div>

                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                      <label htmlFor="remarksId" className="d-inline-block fw-bold mb-1">
                        {t('ReviewOrder.Notes')}
                      </label>
                      <textarea
                        id="remarksId"
                        rows={3}
                        className="form-control mt-1 mb-3"
                        value={remarks}
                        onChange={(e) => setRemarks(e.currentTarget.value)}
                      />

                      {productType === WoodProductName && (
                        <>
                          <span className="mb-2">{t('ReviewOrder.OptionsRestrictions')}</span>
                          <Form.Check
                            type="checkbox"
                            id={`id-${t('ReviewOrder.StorageRoomCleaning')}`}
                            label={previewOrderData?.storageRoomCleaningText}
                            name="storageRoomCleaning"
                            value={(!values.storageRoomCleaning).toString()}
                            checked={values.storageRoomCleaning}
                            onChange={async (e) => {
                              handleChange(e);

                              await prepareOrderData({
                                ...values,
                                storageRoomCleaning: e.target.value === 'true',
                              });
                            }}
                          />
                          <Form.Check
                            type="checkbox"
                            id={`id-${t('ReviewOrder.SmallVehicle')}`}
                            label={previewOrderData?.smallVehicleText}
                            name="smallVehicle"
                            value={(!values.smallVehicle).toString()}
                            checked={values.smallVehicle}
                            onChange={async (e) => {
                              handleChange(e);

                              await prepareOrderData({
                                ...values,
                                smallVehicle: e.target.value === 'true',
                              });
                            }}
                          />
                          <Form.Check
                            type="checkbox"
                            id={`id-${t('ReviewOrder.HoseLength')}`}
                            label={previewOrderData?.hoseLengthText}
                            name="hoseLength"
                            value={(!values.hoseLength).toString()}
                            checked={values.hoseLength}
                            onChange={async (e) => {
                              handleChange(e);

                              await prepareOrderData({
                                ...values,
                                hoseLength: e.target.value === 'true',
                              });
                            }}
                          />
                        </>
                      )}

                      {selectedPromotion && selectedPromotion.promotionGifts?.length > 0 && (
                        <>
                          <span className="mt-2 mb-2">{t('ReviewOrder.PromotionGiftSelect')}</span>
                          {touched.selectedPromotionGift && errors.selectedPromotionGift && (
                            <span className="d-block text-danger">{errors.selectedPromotionGift}</span>
                          )}
                          <select
                            id="selectedPromotionGiftId"
                            className={'form-select'}
                            name="selectedPromotionGift"
                            value={values.selectedPromotionGift}
                            onChange={async (e) => {
                              handleChange(e);
                              const selectedPromotionValue = e.target.value;
                              if (previewOrderData) {
                                previewOrderData.promotionGift = selectedPromotion?.promotionGifts.find(
                                  (item) =>
                                    item.descriptionDE === selectedPromotionValue ||
                                    item.descriptionFR === selectedPromotionValue,
                                );
                                setPreviewOrderData(previewOrderData);
                              }
                            }}
                            required
                          >
                            <option value="">{t('ReviewOrder.PromotionGiftDefault')}</option>
                            {selectedPromotion?.promotionGifts.map((apc) => (
                              <option key={apc.id} value={language === 'de' ? apc.descriptionDE : apc.descriptionFR}>
                                {language === 'de' ? apc.descriptionDE : apc.descriptionFR}
                              </option>
                            ))}
                          </select>
                        </>
                      )}
                    </div>
                  </ResponsiveRow>

                  {errors.acceptedOrderConditions && (
                    <span className="mt-3 mb-1 text-danger fw-bold">{errors.acceptedOrderConditions}</span>
                  )}
                  <Form.Check
                    className={`${errors.acceptedOrderConditions ? '' : 'mt-3'} text-danger`}
                    type="checkbox"
                    id={`id-${t('ReviewOrder.AcceptConditions')}`}
                    label={
                      <a
                        href={
                          language === 'fr'
                            ? 'https://www.agrola.ch/files/uploads/AGB/AGB_Kundentank_F.pdf'
                            : 'https://www.agrola.ch/files/uploads/AGB/AGB_Kundentank_D.pdf'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('ReviewOrder.AcceptConditions')}
                      </a>
                    }
                    name="acceptedOrderConditions"
                    value={(!values.acceptedOrderConditions).toString()}
                    checked={values.acceptedOrderConditions}
                    onChange={handleChange}
                  />
                </Flex>

                <div className="d-flex mt-4">
                  <button
                    className="btn btn-secondary me-1"
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => prevStep()}
                  >
                    <span>{'< ' + t('DeliveryDate.Back')}</span>
                  </button>
                  <button className="btn btn-primary ms-1" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <div className="spinner-border spinner-border-sm text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <span>{t('ReviewOrder.SendOrder') + ' >'}</span>
                    )}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Flex>
    </Step>
  );
}

export default withErrorHandling(ReviewOrderStep);
