import React, { useContext } from 'react';

/**
 *
 * This hook is used by Components that are wrapped in a Stepper
 * Component, in order to access its context values and methods.
 *
 * @returns the context values.
 */
export function useStepper<TContext>(context: React.Context<TContext>): TContext {
  const stepperContext = useContext(context);

  return stepperContext;
}
