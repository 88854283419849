import { useEffect, useState } from 'react';
import { useOrderQueryParams } from '../../hooks';
import { useApplicationStatus } from '../../hooks';
import { OrderFormProvider } from '../../contexts/order-form/OrderFormContext';
import { DeliveryAddress, Interval, Title } from '../../contexts';
import { Product } from '../../models/offers-service/Product';
import { Shop } from '../../models/offers-service/Shop';
import { OrderFormPostalCode, OrderPhase, Promotion, UnloadingPlace } from '../../models';
import { PriceResponse } from '../../models/offers-service/PriceResponse';
import { OrderFormContextValues } from '../../contexts/order-form/types/OrderFormContextValues';
import { OrderTimeoutInMs } from '../../assets/constants/Constants';
import convertToUnicode from '../../utils/unitCode';

interface OrderFormContextProviderProps {
  children: ((contextValues: OrderFormContextValues) => React.ReactNode) | React.ReactNode;
}

/**
 *
 * Checks whether a prop is a function.
 *
 * @param prop - The prop to check.
 *
 * @returns type guard for function type.
 */
function isFunction(prop: unknown): prop is Function {
  return typeof prop === 'function';
}

/**
 *
 * This Component wraps the logic for retrieving the app status
 * and initialize the Context for the OrderForm Component.
 *
 * @param children - an object of type React.ReactNode, which is the
 *                   OrderForm Component.
 *
 * @returns the children wrapped in the OrderFormProvider context Component.
 */
const OrderFormContextProvider = ({ children }: OrderFormContextProviderProps): JSX.Element | null => {
  const mainPageQueryParams = useOrderQueryParams();

  const [retrievedStatus, setRetrievedStatus] = useState<boolean>(false);

  const { getApplicationStatus } = useApplicationStatus();

  const [path, setPath] = useState<OrderPhase>();

  const [postalCode, setPostalCode] = useState<OrderFormPostalCode>({
    npa: mainPageQueryParams.postalCode ?? '',
    name: '',
  });
  const [quantity, setQuantity] = useState<number>(mainPageQueryParams.quantity ?? 0);
  const [unloadingPlaces, setUnloadingPlaces] = useState<number>(mainPageQueryParams.unloadingPlaces || 1);
  const [unloadingPlaceItems, setUnloadingPlaceItems] = useState<UnloadingPlace[]>([]);

  const [shop, setShop] = useState<Shop>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [priceDetails, setPriceDetails] = useState<PriceResponse>();

  const [selectedQuantity, setSelectedQuantity] = useState<number>(priceDetails?.productQuantity ?? 0);
  const [selectedUnloadingPlaces, setSelectedUnloadingPlaces] = useState<number>(
    priceDetails?.unloadingPlacesCount || 1,
  );

  const [remarks, setRemarks] = useState<string>('');

  const [deliveryDates, setDeliveryDates] = useState<Date[]>();
  const [fillTank, setFillTank] = useState<boolean>();

  const [title, setTitle] = useState<Title>();
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [company, setCompany] = useState<string | undefined>('');
  const [invoiceAddressStreet, setInvoiceAddressStreet] = useState<string>('');
  const [invoiceAddressNumber, setInvoiceAddressNumber] = useState<string>('');
  const [invoiceAddressPostalCode, setInvoiceAddressPostalCode] = useState<string>('');
  const [invoiceAddressLocation, setInvoiceAddressLocation] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [acceptedPersonalDataConditions, setAcceptedPersonalDataConditions] = useState<boolean>(false);

  const [deliveryAddresses, setDeliveryAddresses] = useState<DeliveryAddress[]>();

  const [acceptedOrderConditions, setAcceptedOrderConditions] = useState<boolean>(false);
  const [storageRoomCleaning, setStorageRoomCleaning] = useState<boolean>(false);
  const [smallVehicle, setSmallVehicle] = useState<boolean>(false);
  const [hoseLength, setHoseLength] = useState<boolean>(false);

  const [orderId, setOrderId] = useState<number>();

  const [useDesiredPrice, setUseDesiredPrice] = useState<boolean>(false);
  const [desiredPrice, setDesiredPrice] = useState<number>();
  const [desiredPriceExpiration, setDesiredPriceExpiration] = useState<Interval>(Interval.ThreeDays);

  const [usePriceAlarm, setUsePriceAlarm] = useState<boolean>(false);
  const [priceAlarm, setPriceAlarm] = useState<number>();
  const [priceAlarmExpiration, setPriceAlarmExpiration] = useState<Interval>(Interval.ThreeDays);

  const [usePriceSubscription, setUsePriceSubscription] = useState<boolean>(false);
  const [priceSubscription, setPriceSubscription] = useState<Interval>(Interval.OneDay);

  const [useNewsletter, setUseNewsletter] = useState<boolean>(false);
  const [selectedPromotion, setSelectedPromotion] = useState<Promotion>();

  useEffect(() => {
    const {
      path,

      postalCode,
      quantity,
      unloadingPlaces,

      shop,
      selectedProduct,
      priceDetails,
      selectedQuantity,
      selectedUnloadingPlaces,
      unloadingPlaceItems,

      remarks,

      deliveryDates,
      title,
      name,
      surname,
      company,
      invoiceAddressStreet,
      invoiceAddressNumber,
      invoiceAddressPostalCode,
      invoiceAddressLocation,
      email,
      telephone,
      acceptedPersonalDataConditions,

      deliveryAddresses,

      acceptedOrderConditions,
      storageRoomCleaning,
      smallVehicle,
      hoseLength,

      orderId,

      useDesiredPrice,
      desiredPrice,
      desiredPriceExpiration,

      usePriceAlarm,
      priceAlarm,
      priceAlarmExpiration,

      usePriceSubscription,
      priceSubscription,

      useNewsletter,
      selectedPromotion,
    } = getApplicationStatus();

    path && setPath(path);

    postalCode &&
      setPostalCode({
        npa: mainPageQueryParams.postalCode ?? postalCode.npa,
        name: postalCode.name,
      });

    shop && setShop(shop);
    selectedProduct && setSelectedProduct(selectedProduct);
    priceDetails && setPriceDetails(priceDetails);

    selectedUnloadingPlaces &&
      setSelectedUnloadingPlaces(priceDetails?.unloadingPlacesCount || selectedUnloadingPlaces);
    selectedUnloadingPlaces && setUnloadingPlaces(priceDetails?.unloadingPlacesCount || selectedUnloadingPlaces);

    if (priceDetails) {
      const quantity = convertToUnicode(priceDetails.productQuantity, priceDetails.outputUnitCode);
      setSelectedQuantity(quantity);
      setQuantity(quantity);
    } else {
      selectedQuantity && setSelectedQuantity(selectedQuantity);
      selectedQuantity && setQuantity(selectedQuantity);
    }

    remarks && setRemarks(remarks);

    deliveryDates && setDeliveryDates(deliveryDates);
    fillTank !== undefined && setFillTank(fillTank);

    title && setTitle(title);
    name && setName(name);
    surname && setSurname(surname);
    company && setCompany(company);
    invoiceAddressStreet && setInvoiceAddressStreet(invoiceAddressStreet);
    invoiceAddressNumber && setInvoiceAddressNumber(invoiceAddressNumber);
    invoiceAddressPostalCode && setInvoiceAddressPostalCode(invoiceAddressPostalCode);
    invoiceAddressLocation && setInvoiceAddressLocation(invoiceAddressLocation);
    email && setEmail(email);
    telephone && setTelephone(telephone);
    acceptedPersonalDataConditions !== undefined && setAcceptedPersonalDataConditions(acceptedPersonalDataConditions);

    deliveryAddresses && setDeliveryAddresses(deliveryAddresses);

    acceptedOrderConditions !== undefined && setAcceptedOrderConditions(acceptedOrderConditions);
    storageRoomCleaning !== undefined && setStorageRoomCleaning(storageRoomCleaning);
    smallVehicle !== undefined && setSmallVehicle(smallVehicle);
    hoseLength !== undefined && setHoseLength(hoseLength);

    orderId && setOrderId(orderId);

    useDesiredPrice !== undefined && setUseDesiredPrice(useDesiredPrice);
    desiredPrice && setDesiredPrice(desiredPrice);
    desiredPriceExpiration && setDesiredPriceExpiration(desiredPriceExpiration);

    usePriceAlarm !== undefined && setUsePriceAlarm(usePriceAlarm);
    priceAlarm && setPriceAlarm(priceAlarm);
    priceAlarmExpiration && setPriceAlarmExpiration(priceAlarmExpiration);

    usePriceSubscription !== undefined && setUsePriceSubscription(usePriceSubscription);
    priceSubscription && setPriceSubscription(priceSubscription);

    useNewsletter !== undefined && setUseNewsletter(useNewsletter);

    setRetrievedStatus(true);

    unloadingPlaceItems && setUnloadingPlaceItems(unloadingPlaceItems);

    selectedPromotion && setSelectedPromotion(selectedPromotion);
  }, []);

  const contextValues: OrderFormContextValues = {
    path,
    setPath,

    postalCode,
    setPostalCode,
    quantity,
    setQuantity,
    unloadingPlaces,
    setUnloadingPlaces,

    shop,
    setShop,
    selectedProduct,
    setSelectedProduct,
    priceDetails,
    setPriceDetails,
    selectedQuantity,
    setSelectedQuantity,
    selectedUnloadingPlaces,
    setSelectedUnloadingPlaces,
    unloadingPlaceItems,
    setUnloadingPlaceItems,

    remarks,
    setRemarks,

    deliveryDates,
    setDeliveryDates,

    title,
    setTitle,
    name,
    setName,
    surname,
    setSurname,
    company,
    setCompany,
    invoiceAddressStreet,
    setInvoiceAddressStreet,
    invoiceAddressNumber,
    setInvoiceAddressNumber,
    invoiceAddressPostalCode,
    setInvoiceAddressPostalCode,
    invoiceAddressLocation,
    setInvoiceAddressLocation,
    email,
    setEmail,
    telephone,
    setTelephone,
    acceptedPersonalDataConditions,
    setAcceptedPersonalDataConditions,

    deliveryAddresses,
    setDeliveryAddresses,

    acceptedOrderConditions,
    setAcceptedOrderConditions,
    storageRoomCleaning,
    setStorageRoomCleaning,
    smallVehicle,
    setSmallVehicle,
    hoseLength,
    setHoseLength,

    orderId,
    setOrderId,

    useDesiredPrice,
    setUseDesiredPrice,
    desiredPrice,
    setDesiredPrice,
    desiredPriceExpiration,
    setDesiredPriceExpiration,

    usePriceAlarm,
    setUsePriceAlarm,
    priceAlarm,
    setPriceAlarm,
    priceAlarmExpiration,
    setPriceAlarmExpiration,

    usePriceSubscription,
    setUsePriceSubscription,
    priceSubscription,
    setPriceSubscription,

    useNewsletter,
    setUseNewsletter,
    isTimeoutReached: function (): boolean {
      const currentTimeAsMs = Date.now();
      const offerTimeAsMs = new Date(priceDetails?.offerDate.toString() ?? '').getTime();
      return offerTimeAsMs + OrderTimeoutInMs < currentTimeAsMs;
    },
    selectedPromotion,
    setSelectedPromotion,
  };

  if (!retrievedStatus) {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <OrderFormProvider value={contextValues}>
      {isFunction(children) ? children(contextValues) : children}
    </OrderFormProvider>
  );
};

export default OrderFormContextProvider;
