/**
 *
 * This file renders our main App component inside the html root and
 * sets up the Context to be shared between components, in this particular
 * case we use the Application Insights variant provided by the official
 * Microsoft SDK package for Application Insights. We also set up the Error
 * Boundary, also with the App Insights official variant.
 *
 */
import '@progress/kendo-theme-bootstrap/dist/all.css';
import './custom-bootstrap.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';
import App from './App';

fetch('/config.json')
  .then((response) => response.json())
  .then((data) => {
    ReactDOM.render(
      <React.StrictMode>
        <AppInsightsContext.Provider value={reactPlugin}>
          <AppInsightsErrorBoundary
            appInsights={reactPlugin}
            onError={/*Real error component to be used here.*/ () => <></>}
          >
            <App {...data} />
          </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  })
  .catch((error) => console.error('Error while loading the configuration.', error));
