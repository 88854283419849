import React from 'react';
import { useStepper } from '../../../hooks';
import OrderStepperContext from '../../../contexts/stepper/OrderStepperContext';

/**
 *
 * This interface describes all the props that can be passed
 * to the Step component.
 *
 */
interface StepProps {
  children: React.ReactNode;
  backButton?: React.ReactNode;
  nextButton?: React.ReactNode;
  form?: boolean;
}

/**
 *
 * This is the step component that wraps the step logic around
 * every type of component by adding the next and previous step button.
 *
 * @param children - Content of a React JSX element.
 * @param backButton - The back button.
 * @param nextButton - The next button.
 * @param form - Boolean telling the step component whether adding a default
 *               next button or let the form submit be the 'Next' button.
 *
 * @returns the JSX.Element to be used in a React Component.
 *
 */
export default function Step({ children, form, backButton, nextButton }: StepProps) {
  if (form && nextButton) {
    console.warn('In case of prop form to true, nextButton is ignored.');
  }

  const { step, stepsCount } = useStepper(OrderStepperContext)!;

  return (
    <div className="px-1 pb-3">
      {step > 0 && backButton}

      <div className="col">{children}</div>

      {!form && step + 1 < stepsCount && nextButton}
    </div>
  );
}
