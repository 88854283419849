import { useContext } from 'react';
import OrderFormContext from '../contexts/order-form/OrderFormContext';
import { OrderFormContextValues } from '../contexts/order-form/types/OrderFormContextValues';

/**
 *
 * This hook gets the order form context in order to read/set
 * its values in the Components that need it.
 *
 * @returns the order form context values.
 */
export function useOrderFormContext(): OrderFormContextValues {
  const orderFormContext = useContext(OrderFormContext);

  return orderFormContext!;
}
