import { useContext, useMemo } from 'react';
import ProductService, { IProductService } from '../../data/product-service/ProductService';
import AppConfigurationContext from '../../contexts/application/AppConfigurationContext';

/**
 *
 * This hook gets an instance of the ProductService class
 * to be used in a Component.
 *
 * @returns an IProductService interface implemented by
 * an instance of the ProductService class.
 */
export function useProductService(): IProductService {
  const appConfig = useContext(AppConfigurationContext)!;

  const productService = useMemo(() => new ProductService(appConfig), []);

  return productService;
}
