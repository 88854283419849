/**
 * Enum indicating the setting name...
 */
export enum SettingName {
  CustomerShopSetting,
  MaxWoodQuantity,
  MaxFuelQuantity,
  MaxDieselQuantity,
  MinWoodQuantity,
  MinFuelQuantity,
  MinDieselQuantity,
  StorageRoomCleaning,
  HoseLength,
  SmallVehicle,
}
