import { useTranslation } from 'react-i18next';
import { useOrderFormContext, useStepper } from '../../../../hooks';
import OrderStepperContext from '../../../../contexts/stepper/OrderStepperContext';
import Step from '../../../stepper/step/Step';
import ProductSelection from '../../../product-selection/ProductSelection';
import { OrderPhase } from '../../../../models';
import { useCallback } from 'react';

export default function ProductSelectionStep() {
  const { t } = useTranslation();

  const { prevStep } = useStepper(OrderStepperContext)!;

  const { setPath } = useOrderFormContext();

  const setProductSelectionPath = useCallback(
    (path: OrderPhase) => {
      setPath(path);
    },
    [setPath],
  );

  return (
    <Step
      backButton={
        <button className="btn btn-primary mt-4" onClick={() => prevStep()}>
          {`< ${t('PriceBackButton')}`}
        </button>
      }
    >
      <ProductSelection setPath={setProductSelectionPath} />
    </Step>
  );
}
