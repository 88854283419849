import { GetOffersRequest, GetOffersResponse, IAppConfiguration } from '../../models';
import BaseService from '../BaseService';

/**
 *
 * This is the interface that the class PriceApiService
 * is going to implement.
 * A hook is then going to be used to retrieve an instance
 * to be used in the components whenever needed.
 *
 */
export interface IOffersService {
  getOffers(request: GetOffersRequest): Promise<GetOffersResponse[] | undefined>;
}

/**
 *
 * This is the class that implements the IPriceApiService interface.
 * Make use of the related hook to use it in the Components.
 *
 */
export default class OffersService extends BaseService implements IOffersService {
  constructor(config: IAppConfiguration) {
    super(config, '/offers');
  }

  async getOffers(request: GetOffersRequest): Promise<GetOffersResponse[] | undefined> {
    return await this.post<GetOffersResponse[] | undefined>('', request);
  }
}
