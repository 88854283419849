import { StepperContextValues } from '../../../contexts';
import StepperIndicator from '../stepper-indicator/StepperIndicator';

/**
 * The StepperIndicators props.
 */
interface StepperIndicatorsProps {
  /**
   * The current active step.
   * Used for determining how to color the timeline.
   */
  currentStep: number;

  /**
   * The descriptions for the various indicators.
   */
  indicatorsDescription: string[];

  /**
   * The context that wraps the parent stepper.
   */
  context: React.Context<StepperContextValues | undefined>;
}

/**
 *
 * The stepper timeline.
 *
 * @param
 * @returns the Component to be used in JSXs.
 */
export default function StepperIndicators({ currentStep, indicatorsDescription, context }: StepperIndicatorsProps) {
  return (
    <div className="container-fluid d-flex align-items-stretch justify-content-between p-0 py-1">
      {indicatorsDescription.map((indicatorDescription, index) => (
        <StepperIndicator
          key={index}
          activeStep={currentStep}
          stepNumber={index + 1}
          description={indicatorDescription}
          context={context}
        />
      ))}
    </div>
  );
}
