import { IAppConfiguration, OrderData } from '../../models';
import BaseService from '../BaseService';

/**
 * This is the interface that the class OrderService
 * is going to implement.
 * A hook is then going to be used to retrieve an instance
 * to be used in the components whenever needed.
 */
export interface IOrderService {
  createOrder(order: OrderData): Promise<number | undefined>;
  getSummaryPrices(order: OrderData): Promise<OrderData | undefined>;
  getOrderPdfById(orderId: number, language: string): Promise<string | undefined>;
}

/**
 * This is the class that implements the IOrderService interface.
 * Make use of the related hook to use it in the Components.
 */
export default class OrderService extends BaseService implements IOrderService {
  constructor(config: IAppConfiguration) {
    super(config, '/orders');
  }

  async createOrder(order: OrderData): Promise<number | undefined> {
    return await this.post<number | undefined>('', order);
  }

  async getSummaryPrices(order: OrderData): Promise<OrderData | undefined> {
    return await this.post<OrderData | undefined>('/pdf', order);
  }

  async getOrderPdfById(orderId: number, language: string): Promise<string | undefined> {
    return await this.get(`/download/${orderId}/${language}`);
  }
}
