import { IAppConfiguration, PostalCode } from '../../models';
import BaseService from '../BaseService';

/**
 * This is the interface that the class PriceApiService
 * is going to implement.
 * A hook is then going to be used to retrieve an instance
 * to be used in the components whenever needed.
 */
export interface IPostalCodeService {
  getPostalCodesWithNpa(postalCodeNpa: string): Promise<PostalCode[]>;
  getPostalCodeByNpaAndLocation(npa: string, location: string): Promise<PostalCode>;
}

/**
 * This is the class that implements the IPriceApiService interface.
 * Make use of the related hook to use it in the Components.
 */
export default class PostalCodeService extends BaseService implements IPostalCodeService {
  constructor(config: IAppConfiguration) {
    super(config, '/postalCode');
  }

  async getPostalCodesWithNpa(postalCodeNpa: string): Promise<PostalCode[]> {
    return await this.get<PostalCode[]>(`/${encodeURIComponent(encodeURIComponent(postalCodeNpa))}`);
  }

  async getPostalCodeByNpaAndLocation(npa: string, location: string): Promise<PostalCode> {
    return await this.get<PostalCode>(`/${npa}/${encodeURIComponent(encodeURIComponent(location))}`);
  }
}
